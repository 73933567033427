import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Row, Col, Button, Modal } from "antd";
import { getErrorProps } from "@utils/helpers";
import { ButtonComponent, InputText } from "@comps/components";
import { signinUser } from "../authSlice";
import { MailOutlined } from "@ant-design/icons";
import "./SignIn.css"
import RecoverPassword from "./RecoverPassword";

let credentials = {};

const ForgotPassword = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const { loaderState, errors } = useSelector((state) => state.auth);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);


  const [form] = Form.useForm();


  const onFinish = (values) => {
    onCancel();
  };

  const showForgotPasswordModal = () => {
    setForgotPasswordVisible(true);
  };

  const closeForgotPasswordModal = () => {
    setForgotPasswordVisible(false);
  };



  const handleLoginSubmit = (data) => {

    credentials.email = data.email;
    credentials.password = data.password;
    dispatch(signinUser(credentials))
  }

  const validateEmailAtSymbol = (rule, value, callback) => {
    if (value && value.indexOf("@") === -1) {
      callback("Email address must contain the '@' symbol.");
    } else {
      callback();
    }
  };


  return (
    <div style={ { background: '#F3F6F9' } }>
      <Modal open={ visible } onCancel={ onCancel } footer={ null }>
        <img src="./loginicon/Vec.svg" alt="not found" style={ { marginLeft: 80, marginTop: 50 } } />
        <img src="./loginicon/sad.svg" alt="not found" style={ { marginLeft: 130, marginTop: -250 } } />
        <img src="./loginicon/Vec2.svg" alt="not found" style={ { marginLeft: -240, marginTop: -130, width: 30 } } />
        <img src="./loginicon/Vec1.svg" alt="not found" style={ { marginLeft: 245, marginTop: -250 } } />
        <img src="./loginicon/Vec3.svg" alt="not found" style={ { marginLeft: 135, marginTop: -390 } } />
        <img src="./loginicon/Vec2.svg" alt="not found" style={ { marginLeft: 130, marginTop: -360 } } />

        <h1 className="klok-forgot">Forgot Password?</h1>
        <p className="klok-forgot-text">
          Forgot Password? Don’t worry we are here to recover your password
        </p>
        <Form form={ form } onFinish={ onFinish }
          style={ { marginTop: 60 } }
          layout="vertical"
          name="basic"
          initialValues={ { remember: true } }
          className="da-mt-sm-16 da-mt-32"
        >
          <Form.Item
            name="email"
            rules={ [
              { required: false, message: "Please input your email!" },
              { validator: validateEmailAtSymbol }, // Use the custom validator
            ] }
            label="Email address"
            className="da-mb-16"
            { ...getErrorProps(errors?errors['email']:'') }
          >
            <InputText
              placeholder="Enter your Email"
              style={ {
                height: 56,
              } }
              prefix={ <MailOutlined /> }
            />
          </Form.Item>

          <Form.Item className="da-mt-16 da-mb-8">
            <ButtonComponent className="actionButton" type="primary" htmlType="submit" state={ loaderState }
              onClick={ showForgotPasswordModal }>
              Recover Your Password
            </ButtonComponent>
          </Form.Item>
        </Form>
      </Modal>
      <RecoverPassword
        visible={ forgotPasswordVisible }
        onCancel={ closeForgotPasswordModal }
      />
    </div>
  );
};

export default ForgotPassword;
