import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getAllDepartments, deleteDepartment, getFilterReq } from "./requests";
import { FilterComponent, FilterButton } from "@comps/components";
import { Commons, Const } from "../constants";
import CreateDepartment from './components/CreateDepartment';
import { message } from 'antd';
import UpdateDepartment from './components/UpdateDepartment';

// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["department"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["department"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexDepartments = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: trans(Commons.code),
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      dataIndex: "name",
    },
    {
      key: trans(`${langKey}.company`),
      title: trans(`${langKey}.company`),
      dataIndex: "company_name",
    },
    {
      key: trans(`${langKey}.hodName`),
      title: trans(`${langKey}.hodName`),
      dataIndex: "hod_name",
    },
    {
      key: trans(`${langKey}.parentName`),
      title: trans(`${langKey}.parentName`),
      dataIndex: "parent_name",
    },
    {
      key: trans(Commons.identifier),
      title: trans(Commons.identifier),
      dataIndex: "identifier_name",
    },
    {
      key: trans(`${langKey}.maxHeadCount`),
      title: trans(`${langKey}.maxHeadCount`),
      dataIndex: "max_head_count",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDepartments, payload, onSuccess, onSuccess);
  };

  const onSuccess = (response) => {
    setPagination(response.departments);
    setDataSource(response.departments.data);
    //setIdentifiers(response.identifiers)
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateDepartment departments={ dataSource } onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateDepartment departments={ dataSource } identifiers={ identifiers } onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDepartment, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.departments.data);
    message.success("Department deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateDepartment departments={ dataSource } onCompleted={ onCompleted } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };
  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="department"
    />
    )
  }
  const onCloseFilter = () => {
    setChildComponent(null);
  }
  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
        <FilterButton onClick={ onOpenFilter } />
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexDepartments;

const filterFields = [
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
  {
    type: "select",
    placeholder: trans(Commons.identifier),
    key: "department_identifier",
    data_key: "resources.department_identifiers.data",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: "HOD",
    key: "hod_id",
    data_key: "resources.employments.data",
    //is_resource: true
  }
]
