import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { Space, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { Header } from "antd/es/layout/layout";
import CreateTimeSlot from "./components/CreateTimeSlot";
import UpdateTimeSlot from "./components/UpdateTimeSlot";
import { convertTimeTotz } from '@comps/commonFunctions';

const langKey = Const["timeslot"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["timeslot"].route
      }
    ]
  }
}

const IndexTimeSlots = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [breaks, setBreaks] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states
  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(`${langKey}.code`),
      dataIndex: "code",
    },
    {
      key: 'name',
      title: trans(`${langKey}.name`),
      dataIndex: "name",
    },
    {
      key: 'start',
      title: trans(`${langKey}.start`),
      render: (record) => {
        return convertTimeTotz(record.start).format('hh:mm a');
      }
    },
    {
      key: 'end',
      title: trans(`${langKey}.end`),
      render: (record) => {
        return convertTimeTotz(record.end).format('hh:mm a');
      }
    },
    {
      key: 'duration_minutes',
      title: trans(`${langKey}.duration_minutes`),
      render: (record) => {
        return (Math.floor(record.duration_minutes / 60) + ' hours')
      }
    },
    {
      key: 'is_full_overtime_slot',
      title: trans(`${langKey}.is_full_overtime_slot`),
      render: (record) => {
        return record.is_full_overtime_slot ? 'Yes' : "No"
      }
    },
    {
      key: 'is_flexible',
      title: trans(`${langKey}.is_flexible`),
      render: (record) => {
        return record.is_flexible ? 'Yes' : "No"
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();


    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {

    setPagination(response.time_slots);
    setBreaks(response.time_slots.breaks);
    setDataSource(response.time_slots.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateTimeSlot onCreated={ onCreated } record={ record } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateTimeSlot onCreated={ onCreated } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.time_slots.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateTimeSlot onCreated={ onCreated } onSuccess={ onSuccess } />);
  };

  const onCreated = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    if(data){
      getAllData()
    }

    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexTimeSlots;
const filterFields = [
  {
    type: "text",

    placeholder: "Code",
    key: "code",
    data_key: "code",
    //is_resource: true
  },
  {
    type: "text",
    placeholder: "Name",
    key: "name",
    data_key: "name",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.short_overtime`),
    key: "short_overtime",
    data_key: "supporting_data.short_overtime",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.full_overtime`),
    key: "full_overtime",
    data_key: "supporting_data.full_overtime",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.is_flexible`),
    key: "is_flexible",
    data_key: "supporting_data.is_flexible",
    //is_resource: true
  }
]

