
import { get, del, post, postFd, put, download } from "@utils/axios";
const api = "roster";
export const getAllDataReq = (payload) => {
  return get(`${api}?page=1&filters=${payload}`)//filters=employee_|id
};

export const createDataReq = (payload) => {
  return post(`${api}/create-shift-batch?page=1`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};

export const getRelatedDataReq = (payload) => { //time_slots
  return get(`${api}/get-related-data`);
};

export const getCompanyRelatedDataReq = (payload) => {
  return get(`employment-picker/get-company-related-data/1`);
};

export const getTotalHoursReq = (payload) => {
  return get(`${api}/get-total-hours?page=1&emp_ids=${payload}`); //employee_|id; & emp_ids='1,2,3'
};


export const getEmploymentsDataReq = (payload) => {
  return get(`resources/employment?page=1&filters=privilege_|false;`);
};


export const getPreviewDataReq = (payload) => {
  return post(`${api}/preview`, payload);
};
export const filterEmploymentsReq = (payload) => {
  return get(`employment-picker/all?page=${-1}&filters=${payload.filters}`);
};


export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}

//this function is common for required data on all requests filters
export const getFilterForRequestTypesReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,request_status;organization_structure_fields_|location,branch,department;`)
}

export const getNextRequesteeByRequestType = (payload) => {
  return get(`resources/${payload.type}?page=${payload.page}`)
}
export const getAllEmploymentsReq = (payload) => {
  return get(`resources/employment?page=1&filters=${payload.filters}`)
}
export const getEmploymentsResourseReq = (payload) => {
  return get(`resources/employment?page=${payload.page}&filters=${payload.filters}`)
}
export const getAllEmploymentByIdReq = (payload) => {
  return get(`resources/employment?page=${payload.page}&filters=${payload.filters}`)
}
export const getAllEmploymentWithPagination = (payload) => {
  return get(`resources/employmentWithPagination?page=${payload.page}&filters=${payload.filters}`)
}

export const getEmploymentExcludeId = (id) => {
  return get(`resources/employment?page=1&filters=exclude_ids_|${id}`);
};

export const createConsentReq = (payload) => {
  return post(`${payload.api}`, payload);
};
export const createMessageConsentReq = (payload) => {
  return post(`${payload.api}`, payload);
};

export const approveCancelRequestReq = (payload) => {
  return post(`cancelRequestApprovals/approveRequest?page=1&filters=${payload.filters}`, payload)
};

export const getRosterReq = (payload) => {
  return post(`${payload.api}/getRoster`, payload); //relaxation[employment_id]: d46dfabc-2966-42ca-a990-c7bfa0ef4343
  //relaxation[date]: 2023 -09 - 13T00:00:00.000Z
};

export const getPayItems = () => {
  return get("common/get-payitems")
}

export const getFormulasResourceReq = (payload) => {
  return get(`resources/formulas?page=${payload.page}`)
}

//Navigation requests
export const getNavigationReq = (payload) => {
  return get(`nav`)
}
export const getNavNotificationsReq = (payload) => {
  return get(`nav/count_by_key?nav_key=${payload}`)
}

export const getCompanyRelatedData = (payload) => {
  return get(`employment-picker/get-company-related-data/1`)
}
export const getDepartmentHierarchy = (payload) => {
  return get(`directives/departmentHierarchy`)
}
export const getPreviewReq = (payload) => {
  return post(`${payload.moduleName}/preview`, payload)
}
export const getDelegatesReq = (payload) => {
  return post(`${payload.api}/?page=${payload.page}&filters=${payload.filters}`, payload.data)
}

export const getDelegateSupportingFilterReq = (payload) => {
  return get(`filters?company_id=1&fields=page=1&filters=supporting_fields_|employments,request_status;organization_structure_fields_|location,branch,department;`)
}
export const getEmployeesDataReq = (payload) => {
  return get(`resources/employee?page=1&filters=privilege_|false;`);
};
export const getSubordinateEmploymentsDataReq = (payload) => {
  return get(`resources/employment?page=${payload.page}&filters=subordinate_|${payload.filters};`);
};
//download 
export const downloadSampleReq = (payload) => {
  
  return download(`${payload}`)
};
//import
export const importSampleReq = (payload) => {
  return postFd(`${payload.api}`, payload)
}

//when update get employees or employments by ids

//resources
export const getResourceDataReq = (payload) => {
  return get(`resources/${payload.type}?page=${payload.page}&filters=${payload.filters};`);
};

export const getEmploymentsByCriteria = (payload) => {
  return get(`get-employments-by-module/${payload.findBy}/${payload.id}`);
}


//bulk approval
export const approveBulkRequests = (payload) => {
  if (payload.method === 'post') {
    return post(`${payload.api}`, payload);
  }
  return put(`${payload.api}`, payload);
}

export const getAllTimeZonesReq = () => {
  return get("common/getTimezones")
};

export const getAllCurrenciesReq = () => {
  return get("common/getCurrencies")
};

export const getAllcountriesReq = () => {
  return get("countries/all?")
}
export const getStatesByCountryReq = (id) => {
  return get(`states/all?filters=country_|${id}`)
}

export const getCitiesByStateReq = (id) => {
  return get(`cities/all?filters=state_|${id}`)
}
export const getRelationShips = (id) => {
  return get(`employee/contacts/get-related-data?`)
}
export const getJobResultErrors = (id) => {
  return get(`common/get-job-result-errors/${id}`)
}

export const getBatchProgressReq = () => {
  return get(`batch-progress`)
};
export const cancelPayrollJobReq = (payrollId) => {
  return get(`payrolls/update-history/${payrollId}`)
};
export const getReportJobByBatchidReq = (batchId) => {
  return get(`get-report-job/${batchId}`)
};
