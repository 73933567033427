import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  FilterComponent,
  FilterButton,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getAllExperiencesReq, deleteExperienceReq, getFilterReq } from "./requests";
import { Commons, Const } from "../constants";
import { checkPermissions } from '@comps/commonFunctions';
import CreateExperience from './components/CreateExperience';
import { message } from "antd";
import UpdateExperience from "./components/UpdateExperience";
import moment from "moment";


// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["experience"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["experience"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexExperience = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState(props.filters);
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: "full_name",
      title: trans(`${langKey}.employee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record } />
      },
    },
    {
      key: "employer_name",
      title: trans(`${langKey}.employer_name`),
      dataIndex: "employer_name",
    },
    {
      key: "designation",
      title: trans(`${langKey}.designation`),
      dataIndex: "designation",
    },
    {
      key: "company_name",
      title: trans(`${langKey}.company_name`),
      dataIndex: "company_name",
    },
    {
      key: "country",
      title: trans(`${langKey}.country`),
      dataIndex: "country",
    },
    {
      key: "from_date",
      title: trans(`${langKey}.from_date`),
      render: (record) => {
        return moment(record.from_date).format('YYYY-MM-DD')
      }
    },
    {
      key: "to_date",
      title: trans(`${langKey}.to_date`),
      render: (record) => {
        return moment(record.to_date).format('YYYY-MM-DD')
      }
    },
    {
      key: "country",
      title: trans(`${langKey}.country`),
      dataIndex: "country",
    },
    {
      key: "city",
      title: trans(`${langKey}.city`),
      dataIndex: "city",
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllExperiencesReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.employee_experiences.meta.pagination);
    setDataSource(response.employee_experiences.data);
  };
  const onEdit = (record) => {
    setChildComponent(
      <UpdateExperience onCompleted={ onCompleted } record={ record } disabled={ false } filters={ props.filters } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      //<UpdateExperience onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteExperienceReq, record.id, onDeleted, onError);
  };
  const onDeleted = (data, res) => {
    onSuccess(data)
    message.success("Experience Deleted successfully");
    setDataSource(data.employee_experiences.data);
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateExperience onCompleted={ onCompleted } onSuccess={ onSuccess } filters={ props.filters } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };
  //---------------filters-----------------------
  

  const onOpenFilter = () => {

    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="address"
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }


  //----end filter--------------------
  return (
    <> 
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        { checkPermissions('profile_employeeExperience') &&
        <>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } /></>
        }
        { props.filters === undefined && <>
          <FilterButton onClick={ onOpenFilter } /> </> }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ !props.filters ? pagination : undefined } setCurrentPage={ setCurrentPage } currentPage={ currentPage } display={ !props.filters } />
    </>
  );
};

export default IndexExperience;
const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.employee`),
    key: "employments",
    data_key: "supporting_data.employees",
    //is_resource: true 
    resource_type: 'employee'
  },
  {
    type: "text",
    placeholder: "Employer Name",
    key: "employer_name",
  },
  {
    type: "text",
    placeholder: "Designation",
    key: "designation",
  },
  {
    type: "date",
    placeholder: "From",
    key: "from"
  },
  {
    type: "date",
    placeholder: "To",
    key: "to"
  },
]