import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  FilterComponent,
  FilterButton,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { checkPermissions } from '@comps/commonFunctions';
import { getAllRelativesReq, deleteRelativeReq, getFilterReq } from "./requests";
import { Commons, Const } from "../constants";
import CreateRelative from './components/CreateRelative';
import { message } from "antd";
import UpdateRelative from "./components/UpdateRelative";
// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["relative"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["relative"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexRelatives = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState(props.filters);
  const [childComponent, setChildComponent] = useState(null);

  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: "full_name",
      title: trans(`${langKey}.employee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record } />
      },
    },
    {
      key: "relative_name",
      title: trans(`${langKey}.name`),
      dataIndex: "relative_name",
    },
    {
      key: "relationship",
      title: trans(`${langKey}.relationship`),
      dataIndex: "relationship",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllRelativesReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.employee_relatives.meta.pagination);
    setDataSource(response.employee_relatives.data);
  };
  const onEdit = (record) => {
    setChildComponent(
      <UpdateRelative onCompleted={ onCompleted } record={ record } disabled={ false } filters={ props.filters } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateRelative onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteRelativeReq, record.id, onDeleted, onError);
  };
  const onDeleted = (data, res) => {
    onSuccess(data)
    message.success("Relative Deleted successfully");
    setDataSource(data.employee_relatives.data);
  };
  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateRelative onCompleted={ onCompleted } onSuccess={ onSuccess } filters={ props.filters } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };
  //---------------filters-----------------------
  

  const onOpenFilter = () => {

    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="address"
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }


  //----end filter--------------------
  return (
    <>  
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        { checkPermissions('profile_employeeRelative') &&
         <>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } /></>
        }
        { props.filters === undefined && <>
          <FilterButton onClick={ onOpenFilter } /> </> }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ !props.filters ? pagination : undefined } setCurrentPage={ setCurrentPage } currentPage={ currentPage } display={ !props.filters } />
    </>
  );
};

export default IndexRelatives;
const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.employee`),
    key: "employments",
    data_key: "supporting_data.employees",
    //is_resource: true
    resource_type: 'employee'
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.relationship`),
    key: "relation_name",
    data_key: "supporting_data.relation_name",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.is_dependant`),
    key: "is_dependant",
    data_key: "supporting_data.is_dependant",
    //is_resource: true
  },
  {
    type: "text",
    placeholder: "Name",
    key: "name",
  },
  {
    type: "text",
    placeholder: "CNIC",
    key: "cnic",
  },
  {
    type: "date",
    placeholder: "From",
    key: "from"
  },
  {
    type: "date",
    placeholder: "To",
    key: "to"
  },
]