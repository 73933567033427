import { Col, Row } from "antd";
import { Const } from "./constants";
import { useEffect, useState } from "react";
import { trans } from "@comps/components";
import { convertMinutesToHours } from '@comps/commonFunctions'
import dayjs from 'dayjs'
import "./style.css"
const langKey = Const["myAttendance"].lngKey

const AttendanceSummary = (props) => {

  const [employee, setEmployee] = useState()
  const [summary, setSummary] = useState()
  
  useEffect(() => {
    setRecord()
    // eslint-disable-next-line
  }, [props.attendanceSummary])

  const setRecord = () => {
    const updatedSummary = {
      employee: props.employee,
      start_date: dayjs(props.dates.from).format('YYYY-MM-DD'),
      end_date: dayjs(props.dates.end).format('YYYY-MM-DD')
      ,
      ...props.record
    }

    setSummary(updatedSummary);
    setEmployee(props.employee)
  }

  if (summary && employee) {
    const keys = Object.keys(summary);
    return (
      <>
        <h5 className="formlist-title">{ trans(`${langKey}.summary`) }</h5>

        <Row>
          {
            keys.map(key => (
              <Col lg={ 4 } sm={ 24 } xs={ 24 } className=" p-2" key={ key } style={ { borderLeft: '3px solid #4079FC', background: "white", marginBottom: 5 } }>
                <span className="attendance-summary">
                  { trans(`${langKey}.${key}`) }
                </span>
                :
                <span style={ { float: "right", color: "#687980" } } >
                  { (key === 'worked_minutes' || key === 'expected_minutes') || key === 'short_minutes' ? convertMinutesToHours(summary[key]) : summary[key] }
                </span>
              </Col>
            )
            )
          }
          <Col lg={ 4 } sm={ 24 } xs={ 24 } className=" p-2" key={ 'manually' } style={ { borderLeft: '3px solid #4079FC', background: "white", marginBottom: 5 } }>
            <span className="attendance-summary">
              Manually Updated:
            </span>
            <span key={ 'manual' } style={ { float: "right", color: "#687980" } }>{ props.manuallyUpdatedCount }</span>
          </Col>
        </Row>

        <Row>
          <div class="ng-scope">
            <div ng-if="attView=='List'" className="table-legends-wrapper text-right ng-binding ng-scope">
              <span className="badge badge-single bg-success">P</span>
              Present
              <span className="badge badge-single bg-danger">M</span>
              Missing
              <span className="badge badge-single bg-error dker">A</span>
              Absent
              <span className="badge badge-single bg-orange">S</span>
              Short Duration
              <span className="badge badge-single bg-day-off">D</span>
              Day Off
              <span className="badge badge-single bg-default-time-slot">R</span>
              Rest Day
              <span className="badge badge-single bg-tr-black dker">H</span>
              Holiday
              <span className="badge badge-single bg-informed-late">R</span>
              Relaxation
              <span className="badge badge-single bg-darkgray">O</span>
              Official Duty
              <span className="badge badge-single bg-greensea dker">L</span>
              Leave
              <span className="badge badge-single bg-info">T</span>
              Travel
              <span className="badge badge-single badge-empty bg-f5 display-inline">W</span>
              Weekend
            </div>
          </div>
        </Row>

      </>
    )
  }
}


export default AttendanceSummary