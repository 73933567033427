import { useState, useEffect } from "react";
import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  FilterComponent,
  FilterButton,
  EmployeeWithProfile,
  PaginationComponent,  ImportButton,DownloadButton
} from "@comps/components";
import { makeRequest,makeRequestStateless,handleDownloadFile } from "@utils/helpers";
import { getAllSalariesReq, deleteSalaryReq, getFilterReq } from "./requests";
import { Commons, Const } from "../constants";
import CreateSalary from './components/CreateSalary';
import { message } from "antd";
import UpdateSalary from "./components/UpdateSalary";
import ImportSample from "../../common/ImportSample";
import { downloadSampleReq } from "../../common/requests";
// ==================================
//   Page Breadcrumbs Start
// ==================================

const langKey = Const["salary"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["salary"].route,
      },
    ],
  },
};

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexSaleries = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [filters, setFilters] = useState(props.filters);
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: "full_name",
      title: trans(`${langKey}.full_name`),
      render: (record)=>{
        return <EmployeeWithProfile record={record}/>
      },
    },
    {
      key: "department_name",
      title: trans(`${langKey}.department_name`),
      dataIndex: "department_name",
    },
    {
      key: "ntn_no",
      title: trans(`${langKey}.ntn_no`),
      dataIndex: "ntn_no",
    },
    {
      key: "payroll_name",
      title: trans(`${langKey}.payroll_name`),
      dataIndex: "payroll_name",
    },
    {
      key: "gross_salary",
      title: trans(`${langKey}.gross_salary`),
      dataIndex: "gross_salary",
    },
    {
      key: "bank_name",
      title: trans(`${langKey}.bank_name`),
      dataIndex: "bank_name",
    },
    {
      key: "payment_method",
      title: trans(`${langKey}.payment_method`),
      render: (record) => {
        return record.split_salary?'Bank & Cash':record.payment_method_name
      }
    },
    {
      key: "split_salary",
      title: trans(`${langKey}.split_salary`),
      render: (record) => {
        return record.split_salary?'Yes':'No';
      }
    },
    {
      key: "currency_name",
      title: trans(`${langKey}.currency_name`),
      dataIndex: "currency_name",
    },
    {
      key: "is_tax_exempted",
      title: trans(`${langKey}.is_tax_exempted`),
      dataIndex: "is_tax_exempted",
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllSalariesReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.salaries.meta.pagination);
    setDataSource(response.salaries.data);
  }
  const onEdit = (record) => {
    setChildComponent(
      <UpdateSalary onCompleted={ onCompleted } record={ record } disabled={ false } filters={ props.filters } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateSalary onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteSalaryReq, record.id, onDeleted, onError);
  };
  const onDeleted = (data, res) => {
    onSuccess(res)
    message.success("Salery Deleted successfully");
    setDataSource(data.salaries.data);
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateSalary onCompleted={ onCompleted } onSuccess={ onSuccess } filters={ props.filters } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  //---------------filters-----------------------
  

  const onOpenFilter = () => {

    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="address"
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

//download sample log
const onDownloadSample = () => {
  makeRequestStateless(downloadSampleReq, 'company/employment/salaries/download-sample', onSuccessDownloadSample, onSuccessDownloadSample);
}
const onSuccessDownloadSample = (res, err) => {
  if (res.code === undefined) {
    // Extract file extension
    const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
    const fileName = `salaries.${fileExtension}`
    handleDownloadFile(fileName, res)
  }
  else {
    message.error(res.message)
  }
}
  //----end filter--------------------
  return (
    <> 
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        { props.filters === undefined && <><RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
        <DownloadButton onClick={ onDownloadSample } />
        <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="company/employment/salaries/import-sample" module="Employee Salary" onCompleted={ () => setChildComponent(null) } />) } />
          <FilterButton onClick={ onOpenFilter } /> </> }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }

        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexSaleries;
const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.department_name`),
    key: "department",
    data_key: "organization_structure_data.department",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.full_name`),
    key: "employees",
    data_key: "supporting_data.employments",
    resource_type: "employment"
    //is_resource: true
  },
  {
    type: "select",
    placeholder: "Employment Status",
    key: "trashed_type",
    data_key: "supporting_data.trashed_type",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.payroll_name`),
    key: "payroll",
    data_key: "supporting_data.payroll",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.currency_name`),
    key: "currency",
    data_key: "supporting_data.currency",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.is_tax_exempted`),
    key: "tax_exempted",
    data_key: "supporting_data.tax_exempted",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.payment_method`),
    key: "payment_method",
    data_key: "supporting_data.payment_methods",
    //is_resource: true
  },
  {
    type: "text",
    placeholder: "NTN#",
    key: "ntn",
  },


]