
import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import {
  SelectWithSearch
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getResourceDataReq, createSelectList, checkPermissions, decryptLocalData } from '@comps/commonFunctions';
import { Spin } from 'antd';
import { useSelector } from "react-redux";
import _ from "lodash";

var defaultValue='';
const BaseSelectResource = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);

  if(props.defaultValue){
    defaultValue=props.defaultValue;
  }
  if(props.value){
    defaultValue=props.value;
  }
  useEffect(() => {
    getOptions()
  }, [])

  const getOptions = (searchString) => {
    let exclude_ids = '';
    if (props.exclude_ids) {
      const excludeids = props.exclude_ids.join(',');
      exclude_ids = `exclude_ids_|${excludeids}`;
    }
    //check exlude ids
    let payload = {
      page: 1,
      type: '',
      filters: extraFilters()
    }
    const previousOptions =extraFilters()?decryptLocalData('initialEmployees'): decryptLocalData('initialEmployees')
   
    if (defaultValue && !searchString) {
      payload = {
        page: 1,
        type: props.type,
        filters: `ids_|${defaultValue};${exclude_ids};${extraFilters()}`
      }
      sendRequest(payload,searchString)
      return
    }
    if (previousOptions && defaultValue && !searchString) {
      setOptions(previousOptions)
      return;
    }
    payload = {
      page: 1,
      type: props.type,
      filters: `search_|${searchString};${exclude_ids};${extraFilters()}`
    }

    if (!searchString && options.length > 0) {
      setLoader(false)
    }
    else {
      sendRequest(payload,searchString)
    }
  };

  const sendRequest = (payload,search) => {
    if(search){
        makeRequest(setLoader, getResourceDataReq, payload, onSuccess, onError);
    }else{
      makeRequest(setLoader, getResourceDataReq, payload, onSuccess, onError);
    }
  }
  const onSuccess = (response) => {
    const newOptions = createSelectList(response.resources.data)
    //encryptLocalData('initialEmployees', newOptions)
    setOptions(newOptions)
    setLoader(false)
  }
  const onError = (err, res) => {

  }

  const onClear=()=>{
    defaultValue='';
  
   const payload = {
      page: 1,
      type: props.type,
      filters: extraFilters()
    }
    sendRequest(payload,null)
  }

  const onFocus=()=>{
   const payload = {
      page: 1,
      type: props.type,
      filters: extraFilters()
    }
    if(options.length===0){
      sendRequest(payload,null)
    }
     
  }
const onSearch=(value)=>{
  debouncedGetEmployees(value)
}

const debouncedGetEmployees = useCallback(
  _.debounce((value) => {
    getOptions(value);
  }, 500),
  [] // Empty array ensures the function is created only once
);

  // const isEmployeeDisabled=()=>{
  //   const module=localStorage.getItem('modulekey');
  //     if(module && (module==='leaveRequest' || module.includes('my'))){
  //       return false;
  //     }
  //     else{
  //       return false;
  //     }
  // }
  
  const extraFilters=()=>{
    const module=props.modkey;
      if(module && module.includes('subordinat')){
        const filt=`subordinate_|${authUser.employment_id}`;
        return filt;
      }
      else{
        return '';
      }
  }

  return (
    <>
      <Spin spinning={ loader }>
        <SelectWithSearch
          { ...props }
          defaultValue={ defaultValue?defaultValue:null}
          loading={ loader }
          placeholder='Search Employee'
          options={ options }
          onChange={ (id) => props.onChange(id) }
          onSearch={ onSearch }
          maxTagCount={ 'responsive' }
          allowClear
        onClick={ () => onFocus('') }
        onClear={onClear}
        onFocus={()=>onFocus()}
        />
      </Spin>
    </>
  )
};

export default BaseSelectResource;
