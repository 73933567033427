import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans, CustomButton
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../../constants.js";
import { Space, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteRoleReq, getAllDataReq, getFilterReq } from "./requests.js";
import { Header } from "antd/es/layout/layout";
import CreateRole from "./components/CreateRole";
import UpdateRole from "./components/UpdateRole.jsx";
import { LockClockOutlined } from "@mui/icons-material";
//import UpdateReligion from "./components/UpdateReligion";
import { Button } from 'antd';
import { encryptLocalData } from '@comps/commonFunctions';
import { useSelector } from "react-redux";

const langKey = Const["users"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
const IndexRoles = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  const [newRole, setNewRole] = useState(null)
 //start pagination states
 const [currentPage, setCurrentPage] = useState(1)
 const [pagination, setPagination] = useState({
   "total": 0,
   "count": 0,
   "per_page": 0,
   "current_page": 0,
   "total_pages": 0,
   "links": {}
 });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'name',
      title: trans(Commons.name),
      dataIndex: "name",
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        record.name !== 'Super Admin' &&
        <ActionComponent
          each={ record }
          onDelete={ onDelete }

          onView={ onView }
        >
          <Button icon={ <LockClockOutlined /> } onClick={ () =>
            onEdit(record) } />
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters,currentPage]);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, filters, onSuccess, null);
  };

  const onSuccess = (response) => {
    setDataSource(response.roles);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateRole onCompleted={ onUpdated } record={ record } roleId={ record.id } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateRole onCompleted={ onUpdated } record={ record } roleId={ record.id } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteRoleReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    setDataSource(res.roles);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateRole onCompleted={ onCompleted } />);
  };

  const onCompleted = (data) => {
    if (!data) {
      setChildComponent(null);
      return;
    }
    else {
      setNewRole(data.new_role.id);
      setDataSource(data.roles);
    }
    setChildComponent(null);
  };
  const onUpdated = (data) => {
    
    if (!data || data.length === 0) {
      setChildComponent(null);
      return;
    }
    if (data.roles && data.roles.length) {
      setNewRole(data.new_role.id);
      setDataSource(data.roles);
      setChildComponent(
        null
      );
    }
    if(data && data.permissions && Number(data.role_id)===authUser.role_id){
      encryptLocalData('permissions', data.permissions)
    }
    setChildComponent(null);
  };
  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <Header style={ headerStyle }>
          <Space>
            <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
            <FilterButton onClick={ onOpenFilter } />
          </Space>

        </Header>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
    </>
  );
};

export default IndexRoles;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]

