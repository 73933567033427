import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";

import CreateLendType from "./components/CreateLendType";
import UpdateLendType from "./components/UpdateLendType";

const langKey = Const["lendType"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["lendType"].route
      }
    ]
  }
}
//========================================
const IndexLendType = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [allData, setAllData] = useState({})
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'name',
      title: trans(Commons.name),
      dataIndex: "name",
    },
    {
      key: 'employee_experience',
      title: trans(`${langKey}.employee_experience`),
      dataIndex: "employee_experience",
    },
    {
      key: 'gap_between_lends',
      title: trans(`${langKey}.gap_between_lends`),
      dataIndex: "gap_between_lends",
    },
    {
      key: 'status',
      title: trans(Commons.status),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();


    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setAllData(response)
    setPagination(response.lend_types);
    setDataSource(response.lend_types.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateLendType onCompleted={ onCompleted } record={ record } disabled={ false } allData={ allData } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateLendType onCompleted={ onCompleted } record={ record } disabled={ true } allData={ allData } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.lend_types.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    let errorList = [];
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateLendType onCompleted={ onCompleted } allData={ allData } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    const payload = {
      page: 1
    }
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ () => getFilterReq(payload) } onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexLendType;
const filterFields = [
  {
    key: 'code',
    title: trans(Commons.code),
    dataIndex: "code",
  },
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
  {
    type: "select",
    placeholder: "Status",
    key: "is_active",
    data_key: "supporting_data.is_active",
    //is_resource: true
  },
]
