import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent,
  PaginationComponent,
  EmployeeWithProfile,
  ImportButton,
  DownloadButton
} from "@comps/components";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { Commons, Const } from "./../constants";
import { message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import UpdateQuotaAdjust from "./components/UpdateQuotaAdjust";
import { downloadSampleReq } from "../../common/requests";
import ImportSample from "../../common/ImportSample";
import moment from "moment/moment";


const langKey = Const["quotaAdjust"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["quotaAdjust"].route
      }
    ]
  }
}

const IndexQuotaAdjusts = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [

    {
      key: 'employee_id',
      title: trans(`${langKey}.employee_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'leave_type_id',
      title: trans(`${langKey}.leave_type_id`),
      dataIndex: 'leave_type_name'
    },
    {
      key: 'fiscal_year_start',
      title: trans(`${langKey}.fiscal_year_start`),
      render:(record)=>(
        moment(record.fiscal_year_start).format('YYYY-MM-DD')
      )
    },
    {
      key: 'fiscal_year_end',
      title: trans(`${langKey}.fiscal_year_end`),
      render:(record)=>(
        moment(record.fiscal_year_end).format('YYYY-MM-DD')
      )
    },
    {
      key: 'quota',
      title: trans(`${langKey}.quota`),
      dataIndex: 'total_quota'
    },
    {
      key: 'available',
      title: trans(`${langKey}.available`),
      dataIndex: 'available'
    },
    {
      key: 'availed',
      title: trans(`${langKey}.availed`),
      dataIndex: 'availed'
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();


    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.quota_adjustments);
    setDataSource(response.quota_adjustments.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateQuotaAdjust onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateQuotaAdjust onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.quota_adjustments.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    let errorList = [];
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    //setChildComponent(<CreateQuotaAdjust onCompleted={ onCompleted } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);
  }

  //----end filter--------------------
  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleReq, 'leaveQuotas/downloadSample', onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `quota_adjustment_sample.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  //==================================On Apply Leave====================================

  //============================================================================
  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <DownloadButton onClick={ onDownloadSample } />
          <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="/leaveQuotas/import" module="Quota Adjustment" module_key="quota_adjustment" onCompleted={ () => setChildComponent(null) } />) } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexQuotaAdjusts;
const filterFields = [
  {
    type: "select",
    placeholder: "Employees",
    key: "employees",
    data_key: "supporting_data.employments",
    //is_resource: true
    resource_type:'employment'
  },
  {
    type: "select",
    placeholder: "Leave Type",
    key: "leave_type",
    data_key: "supporting_data.leave_type",
    //is_resource: true
  },
  {
    type: "date",
    picker: "year",
    placeholder: "Year",
    key: "year"
  },


]

