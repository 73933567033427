import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent,
  ImportButton,
  DownloadButton
} from "@comps/components";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import CreateSaleSalaryRevisions from "./components/CreateSalaryRevision";
import UpdateSalaryRevision from "./components/UpdateSalaryRevision";
import ImportSample from "../../common/ImportSample";
import { downloadSampleReq } from "../../common/requests";

const langKey = Const["salaryRevisions"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["salaryRevisions"].route
      }
    ]
  }
}

const IndexSaleSalaryRevisions = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: trans(Commons.code),
      title: trans(Commons.code),
      dataIndex: 'code'
    },
    {
      key: trans(Commons.employment),
      title: trans(Commons.employment),
      render: (record) => (
        record.employment?record.employment.full_name_code:''
      )
    },
    {
      key: trans(`${langKey}.last_salary`),
      title: trans(`${langKey}.last_salary`),
      render: (record) => (
        record.last_salary
      )
    },
    {
      key: trans(`${langKey}.new_salary`),
      title: trans(`${langKey}.new_salary`),
      render: (record) => (
        record.new_salary
      )
    },
    {
      key: trans(`${langKey}.date`),
      title: trans(`${langKey}.date`),
      render: (record) => (
        record.date
      )
    },


    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onView={ onView }
        >
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);
  const getAllData = () => {
    setChildComponent(null)
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {
    setAllData(response);
    setPagination(response.salary_revisions);
    setDataSource(response.salary_revisions.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onEdit = (record) => {
    //setChildComponent(<UpdateSaleSalaryRevision onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ false } />);
  };
  const onView = (record) => {
    setChildComponent(<UpdateSalaryRevision onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };

  const onDelete = (record) => {
    const payload = {
      filters: '', id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.salary_revisions.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateSaleSalaryRevisions onCompleted={ onCompleted } authUser={ authUser } allData={ allData } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }


  //----end filter--------------------

  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleReq, 'salaryRevisions/downloadSample', onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `salary_revision_sample.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <DownloadButton onClick={ onDownloadSample } />
          <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="salaryRevisions/import" module="Salary Revision" onCompleted={ () => setChildComponent(null) } />) } />
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexSaleSalaryRevisions;
const filterFields = [
  {
    type: "select",
    placeholder: 'Employee',
    key: "employees",
    data_key: "supporting_data.employees",
    resource_type: 'employment'
  },
  {
    type: "text",
    placeholder: "Code",
    key: "code"
  },
  {
    type: "date",
    placeholder: "From",
    key: "from"
  },
  {
    type: "date",
    placeholder: "To",
    key: "to"
  },
]

