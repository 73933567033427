import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import CreateProbation from "./components/CreateProbation";
import UpdateProbation from "./components/UpdateProbation";
import ViewProbationHistory from "./components/ViewProbationHistory";
import { getRequestFilters } from "@mods/common/commonFilterFields";
import ViewFlow from '@mods/common/ViewFlow';

const langKey = Const["probation"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["probation"].route
      }
    ]
  }
}

const IndexProbations = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  const [attributes, setAttributes] = useState([]);
  const [scales, setScales] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });


  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'duration',
      title: trans(`${langKey}.duration`),
      dataIndex: "duration",
    },
    {
      key: 'next_requestee',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.next_requestee_name } />
      },
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
    {
      key: 'end_date',
      title: trans(`${langKey}.end_date`),
      render: (record) => {
        return record.end_date
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ record.status !== 'Completed' ? onEdit : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
          onViewHistory={ record.status !== 'Pending' ? onViewHistory : '' }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();


    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setPagination(response.probations);
    setDataSource(response.probations.data);
    setAttributes(response.professional_attributes)
    setScales(response.evaluation_scales)
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateProbation onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateProbation onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewProbationHistory onCompleted={ onCompleted } record={ record } disabled={ true } professional_attributes={ attributes } evaluation_scales={ scales } />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      <ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.probations.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateProbation onCompleted={ onCompleted } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);
  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexProbations;
const filterFields = getRequestFilters('')

