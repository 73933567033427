import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent, PaginationComponent, CustomButton, DownloadButton, ImportButton
} from "@comps/components";
import { makeRequest, replaceById, removeById, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { message } from 'antd';
import { deleteDataReq, getAllDataReq } from "./requests";
import CreateKpi from "./components/CreateKpi";
import UpdateKpi from "./components/UpdateKpi";
import ImportSample from "@mods/common/ImportSample";
import { downloadSampleReq } from "@mods/common/requests";
import { checkPermissions } from "@comps/commonFunctions";
const langKey = Const["kpi"].lngKey;

//========================================
const IndexKpi = (props) => {
  const totalRecords=0;
  const [allData, setAllData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code"
    },
    {
      key: 'title',
      title: trans(`${langKey}.title`),
      dataIndex: "title"
    },
    {
      key: 'short_title',
      title: trans(`${langKey}.short_title`),
      dataIndex: "short_title"
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ checkPermissions('kpi', 'delete') && onDelete }
          onView={ checkPermissions('kpi', 'view') && onView }
          onEdit={ checkPermissions('kpi', 'update') && onEdit }
        >

        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (data, response) => {
    setDataSource(data.data);
    setPagination(data)
    message.info(response.message)
  };


  const onEdit = (record) => {
    setChildComponent(
      <UpdateKpi onCompleted={ onCompleted } record={ record } allData={ allData } disabled={ false } />
    );
  };
  const onView = (record) => {
    setChildComponent(
      <UpdateKpi onCompleted={ onCompleted } record={ record } allData={ allData } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (id) => {
    setDataSource(removeById(dataSource, id))

    message.success("Record Deleted")
    getAllData()
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateKpi onCompleted={ onCompleted } allData={ allData } onSuccess={ onSuccess } />);
  };

  // const onAllocate = () => {
  //   setChildComponent(<AllocateWorkSheet onCompleted={ onCompleted } allData={ allData } onSuccess={ onSuccess } />);
  // }

  const onCompleted = (data) => {
    if (data) {
      setDataSource(replaceById(dataSource, data));
      getAllData()
    }

    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };



  //download sample log
  const onDownloadSample = () => {
    setLoader(true);
    makeRequestStateless(downloadSampleReq, 'kpi-download-sample', onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    setLoader(false)
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `kpi_import_sample.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  //============allocation==============================
  //end allocation
  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent >
          {
            <>
              <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="kpi-import-sample" module="Kpi" onCompleted={ () => setChildComponent(null) } />) } />
              <DownloadButton onClick={ onDownloadSample } />
              <CustomButton onClick={ getAllData } text="Refresh" />
              { checkPermissions('kpi', 'create') && <><RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } /> </> }

            </>

          }
          {/* <CustomButton onClick={ onAllocate } text="Allocate" /> */ }
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexKpi;


