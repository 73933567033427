import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent,
  PaginationComponent,
  EmployeeWithProfile,InputCheck,ButtonComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import moment from "moment/moment";
//import CreateEvaluation from "./components/CreateEvaluation";
import UpdateEvaluation from "./components/UpdateEvaluation";
import ViewFlow from '@mods/common/ViewFlow';
import RequestAsTab from "../../../common/RequestAsTab";
import ViewProbationHistory from "../probation/components/ViewProbationHistory";
import { getRequestFilters } from "@mods/common/commonFilterFields";
import { RefreshButton } from '../../../../wrappers/components';

const langKey = Const["evaluation"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["evaluation"].route
      }
    ]
  }
}

const IndexEvaluations = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [asFilters, setAsFilters] = useState([]);
  const [requestAs, setRequestAs] = useState(8)
  const [noOfNotifications, setNoOfnotifications] = useState(null)
  const [filters, setFilters] = useState();

  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'department_name',
      title: trans(`${langKey}.department_name`),
      render: (record) => {
        return record.requestor.department_name;
      }
    },
    {
      key: 'duration',
      title: trans(`${langKey}.duration`),
      dataIndex: "duration",
    },
    {
      key: 'completion_date',
      title: trans(`${langKey}.completion_date`),
      render: (record) => {
        return record.end_date ? moment(record.end_date).format('YYYY-MM-DD') : '';
      }
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.next_requestee_name } />
      },
    },
    {
      key: 'month_wise_evaluation',
      title: trans(`${langKey}.month_wise_evaluation`),
      render: (record) => {
        return <InputCheck checked={record.month_wise_evaluation} disabled/>
      },
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}

          onViewHistory={ record.status === 'Completed' ? onViewHistory : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
        
          onView={ onView }
        >
          {record.status === 'Completed' ? '' :<ButtonComponent text="Evaluate" onClick={()=>onEdit(record)}/> }
          
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, requestAs, currentPage]);

  const getAllData = () => {
    let payload = {
      page: currentPage,
      filters: filters,
      data: { request_as: requestAs }
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setNoOfnotifications(response.probations.length);
    setPagination(response.probations);
    setDataSource(response.probations.data);
    setAsFilters(response.filters);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateEvaluation onCompleted={ onCompleted } record={ record } disabled={ false } requestAsId={ requestAs } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateEvaluation onCompleted={ onCompleted } record={ record } disabled={ true } requestAsId={ requestAs } />
    );
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewProbationHistory onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      <ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    setDataSource(res.probations.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------
  //=============================Tab Menus========================================
  //==============================================================================
  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <RequestAsTab getAllData={ getAllData } setAsFilters={ asFilters } setRequestAs={ setRequestAs } />
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <RefreshButton onClick={ getAllData } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexEvaluations;
const filterFields = getRequestFilters('')

