import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent,
  ImportButton,
  DownloadButton
} from "@comps/components";
import { makeRequest, makeRequestStateless } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, downloadSampleLogReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import CreateAttendanceLogs from "./components/CreateAttendanceLogs";
import UpdateAttendanceLogs from "./components/UpdateAttendanceLogs";
import ImportMachineAttendance from "./components/ImportMachineAttendance";
import { handleDownloadFile } from '@utils/helpers';
import DownloadSampleLogByDate from "./components/DownloadSampleLogByDate";
import ImportAttendance from "./components/ImportAttendance";
import { convertTimeTotz } from "@comps/commonFunctions";

const langKey = Const["attendanceLogs"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["attendanceLogs"].route
      }
    ]
  }
}

const IndexAttendanceLogs = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [machines, setMachines] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState(props.attendanceof === 'my' ? `employee_|${authUser.employment_id}` : '');
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'employee',
      title: trans(`${langKey}.employee`),
      render: (record) => {
        return (

          <>
            { record.employee ?
              <div>{ record.employee.full_name }<br /><small>{ record.employee.emp_code }</small></div>
              :
              <div className="text-danger">
                { 'User Not Mapped: ' + record.user_id }
              </div>
            }

          </>
        )
      },
    },
    {
      key: 'punch_type',
      title: trans(`${langKey}.punch_type`),
      dataIndex: 'punch_type'
    },
    {
      key: 'punch_time',
      title: trans(`${langKey}.punch_time`),
      render: (record) => {
        return convertTimeTotz(record.punch_time).format('MMMM DD YYYY') + " " + convertTimeTotz(record.punch_time).format('hh:mm A')
      }
    },
    {
      key: 'machine_code',
      title: trans(`${langKey}.machine_code`),
      render: (record) => {
        return record.machine ? record.machine.code : null
      }
    },
    {
      key: 'machine_name',
      title: trans(`${langKey}.machine_name`),
      render: (record) => {
        return record.machine ? record.machine.name : null
      }
    },
    {
      key: 'description',
      title: trans(Commons.description),
      dataIndex: "description",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onEdit={ onEdit }
          onView={ onView }
          onDelete={ onDelete }
        >
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);
  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    setChildComponent(null)
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {

    setAllData(response);
    setPagination(response.attendance_logs);
    setDataSource(response.attendance_logs.data);
    setMachines(response.machines)
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onEdit = (record) => {
    setChildComponent(<UpdateAttendanceLogs onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ false } />);
  };
  const onView = (record) => {
    setChildComponent(<UpdateAttendanceLogs onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };

  const onDelete = (record) => {
    const payload = {
      filters: '', id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    getAllData()
    //setDataSource(res.attendance_logs.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateAttendanceLogs onCompleted={ onCompleted } authUser={ authUser } allData={ allData } onSuccess={ onSuccess } />);
  };
  const onImportMachineAttendance = () => {
    setChildComponent(<ImportMachineAttendance onCompleted={ onCompleted } machines={ machines } getAllData={ getAllData } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    if(data){
      getAllData();
    }
    setChildComponent(null);
  };
  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleLogReq, null, onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `sample_attendance_import_sheet.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  //download sample log
  const onDownloadSampleByDate = (date) => {
    setChildComponent(<DownloadSampleLogByDate onCompleted={ onCompleted } />)
  }
  const onImportAttendance = () => {
    setChildComponent(<ImportAttendance onCompleted={ onCompleted } />)
  }

  //end download samples
  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);
  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <CreateButton text="Refresh" onClick={ getAllData } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <HeaderComponent modkey={props.modkey}>
          <ImportButton text='Import Attendance' onClick={ onImportAttendance } />
          <ImportButton text='Import Machine Attendance' onClick={ onImportMachineAttendance } />
          <DownloadButton onClick={ onDownloadSample } />
          <DownloadButton text='Download Sample By Date' onClick={ onDownloadSampleByDate } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexAttendanceLogs;
const filterFields = [
  {
    type: "select",
    placeholder: 'Employee',
    key: "employments",
    filterKey: 'employee_id',
    data_key: "supporting_data.employments",
    resource_type: 'employee'
  },
  {
    type: "select",
    placeholder: 'Machine Name',
    key: "machine",
    data_key: "supporting_data.machine",
  },
  {
    type: "select",
    placeholder: 'Punch Type',
    key: "punch_type",
    data_key: "supporting_data.punch_type",
    multiple:false
  },
  {
    type: "date",
    placeholder: "From",
    key: "from"
  },
  {
    type: "date",
    placeholder: "To",
    key: "to"
  },
]

