import {
  HeaderComponent,
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans, FilterComponent,
  CreateButton,RefreshButton,
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { useState, useEffect } from 'react';
import { getCompanies } from "./requests"

import CreateCompany from "./components/CreateCompany"
import UpdateCompany from "./components/UpdateCompany"
import { Commons, Const } from "../constants"
// ==================================
//   Page Breadcrumbs Start
// ==================================
const langKey = Const["companies"].lngKey
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["companies"].route
      }
    ]
  }
}
// ==================================
//   Page Breadcrumbs End
// ==================================
const IndexCompanies = (props) => {
  // ==================================
  //   Use States Start
  // ==================================
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [dataTimezones, setTimezones] = useState([]);
  const [dataCurrencies, setCurrencies] = useState([]);
  const [loader, setLoader] = useState(false);
  const [disableCreate, setDisableCreate] = useState(true);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  const [pageSize, setPageSize] = useState()
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: pageSize,
    sortName: 'id',
    sortType: 'desc'
  });
  // ==================================
  //   Use States End
  // ==================================
  // ==================================
  //   Table Data End
  // ==================================
  const columns = [
    {
      key: trans(`${Commons.code}`),
      title: trans(`${Commons.code}`),
      dataIndex: 'code',
      sorter: true,
    },
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: trans(`${langKey}.shortName`),
      title: trans(`${langKey}.shortName`),
      dataIndex: 'short_name',
      sorter: false,
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => <ActionComponent modkey={props.modkey} each={ record } onEdit={ onEdit } onView={ onView }>
      </ActionComponent>
    },
  ];
  // ==================================
  //   Table Data End
  // ==================================
  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [pagination, filters]);

  const getAllData = () => {
    makeRequest(setLoader, getCompanies, filters, onSuccess, onSuccess);
  }

  const onSuccess = (response) => {
    setPageSize(response.companies.per_page)
    setDataSource(response.companies.data);
    setTimezones(response.timezones);
    setCurrencies(response.currencies);

    if (response.companies.data.length > 0) {
      setDisableCreate(true)
    }
    else {
      setDisableCreate(false)
    }
    if (response.companies.data !== undefined && response.companies.data.length > 0) {

      let tzid = response.companies.data[0].timezone_id;
      let timezone = response.timezones.find((each) => each.id === tzid)

      localStorage.setItem('timezone', timezone.name)
    }



  }
  const onEdit = (record) => {
    setChildComponent(<UpdateCompany onCreated={ onCreated } record={ record } disabled={ false } timezones={ dataTimezones } currencies={ dataCurrencies }/>);
  }
  const onView = (record) => {
    setChildComponent(<UpdateCompany onCreated={ onCreated } record={ record } disabled={ true } timezones={ dataTimezones } currencies={ dataCurrencies }/>);
  }
  const onCreate = () => {
    setChildComponent(<CreateCompany onCreated={ onCreated } timezones={ dataTimezones } currencies={ dataCurrencies } />);
  }
  const onCreated = (data) => {
    if (data.length) {
      setDataSource(data)
      getAllData()
    }
    setChildComponent(null);
  }
  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }
  // ==================================
  //   Component JSX Start
  // ==================================
  return (
    <>
      { childComponent }
      
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        { !disableCreate && <><RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } /></> }
      </HeaderComponent>
      <BodyComponent>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}
// ==================================
//   Component JSX End
// ==================================
export default IndexCompanies
