import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from "js-cookie";
import { login } from "./requests";
import { encryptLocalData, decryptLocalData } from '@comps/commonFunctions';

// Define a name for slice
const sliceName = "auth";

// Define the initial state using that type
const initialState = {
  errors: [],
  otpRequired: false,
  loaderState: false,
  authUser: decryptLocalData('user'),
  permissions: {},
}

export const signinUser = createAsyncThunk(
  `${sliceName}/signinUser`,
  async (auth, thunkApi) => {
    const res = await login(auth);
localStorage.clear();
    if (res.success === true || res.code === 200) {
      localStorage.setItem('tenant', res.data.user.tenant);
      localStorage.setItem('timezone', res.data.user.timezone)
      encryptLocalData('user', res.data.user);
      //localStorage.setItem("user", JSON.stringify(res.data.user));
      //localStorage.setItem("token", res.data.access_token);
      encryptLocalData('permissions', res.data.permissions)
      //localStorage.setItem("permissions", JSON.stringify());
      Cookies.set("token", res.data.access_token.replace("Bearer ", "")); // Set this to cookies
      return res.data;
    } else if (res.code === 202) {
      let data = [];
      data.code = 202;
      data.msg = res.msg;
      return thunkApi.rejectWithValue(data);
    } else {
      let errors = [];
      errors['email'] = res
      return thunkApi.rejectWithValue(errors);
    }
  }
);
export const authSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    logout: (state, action) => {
      localStorage.clear();
      Cookies.remove("token");
      return {
        ...initialState,
        authUser: null,

      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signinUser.pending, (state, action) => {
      localStorage.removeItem("user");
      Cookies.remove("token");
      return {
        ...initialState,
        otpRequired: state.otpRequired,
      };
    });
    builder.addCase(signinUser.fulfilled, (state, action) => {
      return {
        ...initialState,
        loaderState: true,
        authUser: action.payload.user,
        permissions: action.payload.permissions
      };
    });
    builder.addCase(signinUser.rejected, (state, action) => {
      if (action.payload && action.payload.code && action.payload.code === 202) {
        return {
          ...initialState,
          otpRequired: true,
          errors: action.payload.msg
        };
      }
      return {
        ...initialState,
        otpRequired: state.otpRequired,
        errors: action.payload
      };
    });
  }
});

export default authSlice.reducer;
export const { logout } = authSlice.actions;
