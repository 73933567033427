import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../../constants";
import { Space, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { Header } from "antd/es/layout/layout";
import CreateInstitute from "./components/CreateInstitute";
import UpdateInstitute from "./components/UpdateInstitute";

const langKey = Const["institute"].lngKey;
const headerStyle = { background: 'white', textAlign: "right" }
const IndexInstitutes = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
 //start pagination states
 const [currentPage, setCurrentPage] = useState(1)
 const [pagination, setPagination] = useState({
   "total": 0,
   "count": 0,
   "per_page": 0,
   "current_page": 0,
   "total_pages": 0,
   "links": {}
 });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'name',
      title: trans(Commons.name),
      dataIndex: "name",
    },
    {
      key: 'description',
      title: trans(Commons.description),
      dataIndex: "description",
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onEdit={ onEdit }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();


    // eslint-disable-next-line
  }, [filters,currentPage]);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, filters, onSuccess, null);
  };

  const onSuccess = (response) => {
    //setTotalRecords(response.institutes.current_page);
    setDataSource(response);
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateInstitute onCompleted={ onCompleted } record={ record } disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateInstitute onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    setDataSource(res);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateInstitute onCompleted={ onCompleted } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <Header style={ headerStyle }>
          <Space>
            <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
            <FilterButton onClick={ onOpenFilter } />
          </Space>

        </Header>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
    </>
  );
};

export default IndexInstitutes;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]

