import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { Navigate, useNavigate } from "react-router-dom";
import UpdateCustomDashboard from "./components/UpdateCustomDashboard";
//import CreateCustomDashboard from "./components/CreateCustomDashboard";
//import ViewCustomDashboard from "./components/ViewCustomDashboard";

const langKey = Const["customDashboard"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["customDashboard"].route
      }
    ]
  }
}
//========================================
const IndexCustomDashboard = (props) => {
  const navigate = useNavigate()
  const totalRecords=0;
  const [allData, setAllData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
 //start pagination states
 const [currentPage, setCurrentPage] = useState(1)
 const [pagination, setPagination] = useState({
   "total": 0,
   "count": 0,
   "per_page": 0,
   "current_page": 0,
   "total_pages": 0,
   "links": {}
 });
 //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [

    {
      key: 'for',
      title: trans(`${langKey}.for`),
      dataIndex: "for",
    },
    {
      key: 'name',
      title: trans(`${langKey}.name`),
      dataIndex: "name",
    },
    {
      key: 'type',
      title: trans(`${langKey}.type`),
      dataIndex: "type",
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onView={ onView }
          onEdit={ onEdit }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters]);

  const getAllData = () => {
    makeRequest(setLoader, getAllDataReq, filters, onSuccess, null);
  };

  const onSuccess = (response) => {
    setAllData(response)
    setPagination(response.custom_dashboards);
    setDataSource(response.custom_dashboards.data);
  };

  const onView = (record) => {
    navigate('view/' + record.id);
  };

  const onEdit = (record) => {
    navigate('edit/' + record.id);
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    setDataSource(res.custom_dashboards.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    navigate('create/')
  };


  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={ setCurrentPage } onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexCustomDashboard;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]

