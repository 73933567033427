import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Form, Input, Row, Col, Tooltip, message } from "antd";
import { Link } from "react-router-dom";
import { getErrorProps } from "@utils/helpers";
import { ButtonComponent, InputText } from "@comps/components";
import { signinUser } from "../authSlice";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import ForgetPassword from "./ForgetPassword"
import { Typography } from "antd";
import "./SignIn.css"
import { HelpCenterOutlined } from "@mui/icons-material";

const { Title, Text } = Typography


let credentials = {};

const SignIn = () => {
  const dispatch = useDispatch();
  const { loaderState, errors } = useSelector((state) => state.auth);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [passwordInputFocused, setPasswordInputFocused] = useState(false);
  const [passwordLength, setPasswordLength] = useState(0);
  const [isEmailValid, setIsEmailValid] = useState(true); // Track email validation status
  const [emailInputFocused, setEmailInputFocused] = useState(false);
  const [loading,setLoading]=useState(false);
  

  useEffect(()=>{
    //getGeoLatLog()
    handleLoading(errors)
  },[errors])

//  const getGeoLatLog=() =>{
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//           console.log("Latitude:", position.coords.latitude);
//           console.log("Longitude:", position.coords.longitude);
//       },
//       (error) => message.info(error.message),
//       { enableHighAccuracy: true }
//   );
  
//   }
  const handleLoading=(errors)=>{
    if(errors && errors.email!==undefined){
      setLoading(false)
    }
    if(errors && errors.email===undefined && credentials.email!==undefined){
      setLoading(true)
    }
    if(errors && errors.email===undefined && credentials.email===undefined){
      setLoading(false)
    }
  }
  const showForgotPasswordModal = () => {
    setForgotPasswordVisible(true);
  };

  const closeForgotPasswordModal = () => {
    setForgotPasswordVisible(false);
  };

  const handleLoginSubmit = (data) => {
    credentials.email = data.email;
    credentials.password = data.password;
    setLoading(true)
    dispatch(signinUser(credentials))
    credentials={}
  }

  const validateEmailAtSymbol = (rule, value, callback) => {
    if (value && value.indexOf("@") === -1) {
      callback("Email address must contain the '@' symbol.");
    } else {
      callback();
    }
  };


  const handlePasswordInputFocus = () => {
    setPasswordInputFocused(true);
    setEmailInputFocused(true);
  };

  const handlePasswordInputBlur = () => {
    setPasswordInputFocused(false);
    setEmailInputFocused(false);
  };

  const checkPasswordLength = (password) => {
    const length = password.trim().length;
    setPasswordLength(length);
  };

  const checkEmailValidity = (email) => {
    // You can use a regular expression or any other method to validate the email
    const isValid = /@/.test(email);
    setIsEmailValid(isValid);
  };



  return (
    <div style={ { background: '#F3F6F9' } }>
      <Row>
        <Col xs={ { span: 12 } }>
          <div className="authRightPanel">
            <img className="klok-logo" src="loginicon/image170.svg" alt="logo" />
            <div style={ { marginLeft: 60 } }>
              <h1 className="title">KLOK</h1>
              <Text className="carousel-text">System for the administration of human resources (HRMS) As a whole, it refers to software that firms employ to manage their internal human resources. 
                <ul>
                  <li>Easy to Manage Employees and Attendance,Simplified Payroll Management and much more</li>
                 
                 {/* <li>
                 <Tooltip title="Help Center"> <a href="https://help.klokhr.com/" target="_blank" style={{textDecoration:'none', color:'white'}}>  <HelpCenterOutlined/></a></Tooltip></li> */}
                 
                </ul>
            
              </Text>
            </div>
            <img src="loginicon/Group2.png" alt="not found" style={ { marginTop: -420 } } />
            <img src="loginicon/Group.png" alt="not found" className="groups" />
            <img src="loginicon/Group.png" alt="not found" className="groups2" />
            <div className="de">
              <img className="login-dashboard" src="loginicon/dashboard_screenshot1.png" alt="not found" />
            </div>
          </div>
        </Col>

        <Col xs={ { span: 12 } }>
          <div className="klok-form-background">
            <div className="klok-input">

              <div className="form-logo">
                <img src="loginicon/image170.svg" alt="not found" />
                <img src="loginicon/KLOK.svg" alt="not found" />
              </div>

              <Title className="klok-form-title">Welcome Back!</Title>

              <div className="klok-form-text">
                <Text className="klok-form-text">

                </Text>
              </div>
              <Form
                style={ { marginTop: 50 } }
                layout="vertical"
                name="basic"
                initialValues={ { remember: true } }
                className="da-mt-sm-16 da-mt-32"
                onFinish={ handleLoginSubmit }
              >
                <Form.Item
                  name="email"
                  rules={ [
                    { required: true, message: "Please input your email!" },
                    // { validator: validateEmailAtSymbol }, // Use the custom validator
                  ] }
                  label="Username or Email"
                  className="da-mb-16"
                  { ...getErrorProps(errors?errors['email']:'') }
                >
                  <InputText
                    placeholder="Enter your Username or Email"
                    style={ {
                      height: 56,
                    } }
                    prefix={
                      <MailOutlined
                        className={ `lock-icon ${emailInputFocused ? "focused" : ""
                          } ${isEmailValid ? "" : "error"}` } // Apply the "error" class when email is invalid
                      />
                    }
                    onFocus={ handlePasswordInputFocus }
                    onBlur={ handlePasswordInputBlur }

                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={ [
                    { required: true, message: 'Please input your password!', },
                    { min: 6, message: 'Minimum password length is 6', },
                    { max: 30, message: 'Maximum password length is 30', },
                  ] }
                  label="Password"
                  className="da-mb-8"
                  { ...getErrorProps(errors?errors['password']:'') }
                >
                  <Input.Password type="password"
                    placeholder="Enter your Password"
                    style={ {
                      height: 56
                    } }
                    prefix={
                      <LockOutlined
                        className={ `lock-icon ${passwordInputFocused ? "focused" : ""} ${passwordLength < 6 ? "error" : ""
                          }` }
                      />
                    }
                    onFocus={ handlePasswordInputFocus }
                    onBlur={ handlePasswordInputBlur }
                    onChange={ (e) => checkPasswordLength(e.target.value) }
                  />
                </Form.Item>

                <Row align="middle" justify="space-between">
                  <Form.Item className="da-mb-0" style={ { marginTop: -5 } }>
                    <Checkbox name="remember">Remember me</Checkbox>
                  </Form.Item>

                  <Link
                    className="da-button da-text-color-black-80" style={ { marginTop: -33, textDecoration: 'none', color: '#4079FC' } }
                    onClick={ showForgotPasswordModal }
                  >
                    Forgot Password?
                  </Link>
                </Row>

                <Form.Item className="da-mt-16 da-mb-8">
                  <ButtonComponent className="actionButton" type="primary" htmlType="submit" state={loaderState} loading={loading}>
                    Log in
                   
                  </ButtonComponent>
                </Form.Item>
              </Form>
              <ForgetPassword
                visible={ forgotPasswordVisible }
                onCancel={ closeForgotPasswordModal }
              />
            </div>
          </div>
        </Col>

      </Row>
    </div>
  );

};

export default SignIn;
