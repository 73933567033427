import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent, ImportButton,DownloadButton
} from "@comps/components";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { Commons, Const } from "../constants";
import {  message, Popconfirm,Tooltip } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { applyIncentiveReq, deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";
import CreateSaleIncentive from "./components/CreateSaleIncentive";
//import UpdateSaleIncentives from "./components/UpdateSaleIncentives";
import UpdateSaleIncentive from "./components/UpdateSaleIncentive";
import { UserOutlined} from "@ant-design/icons";
import { checkPermissions } from '@comps/commonFunctions';
import { CalculateOutlined } from "@mui/icons-material";
import { downloadSampleReq } from "../../common/requests";
import ImportSample from "../../common/ImportSample";
import moment from "moment";
import ViewIncentiveEmployees from "./components/ViewIncentiveEmployees";

const langKey = Const["saleIncentives"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["saleIncentives"].route
      }
    ]
  }
}

const IndexSaleIncentives = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState(props.attendanceof === 'my' ? `employee_|${authUser.employment_id}` : '');
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================


  const columns = [
    
    {
      key: 'branch_id',
      title: trans(`${langKey}.branch_id`),
      dataIndex: 'branch_name'
    },
    {
      key: 'payitem_id',
      title: trans(`${langKey}.payitem_id`),
      dataIndex: 'payitem_name'
    },
    {
      key: 'month',
      title: trans(`${langKey}.month`),
      render: (record)=>(
        moment(record.month).format('YYYY-MM-DD')
      )
    },
    {
      key: 'processed',
      title: trans(`${langKey}.processed`),
      render: (record)=>(
        record.processed?'Yes':'No'
      )
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onEdit={ onEdit }
          onView={ onView }
          onDelete={ onDelete }
        >
           
          <Popconfirm 
            title="Are you sure you want to compute?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => onApplyIncentive(record)}
          >
           <Tooltip title='Apply/Compute'>
              <CalculateOutlined className="icon-style da-text-color-info-1 ms-3 mt-1" style={ { color: "#1677ff" } }/>
            </Tooltip>
            
          </Popconfirm>
       {
        record.processed &&    <Tooltip title='Employees'>
        <UserOutlined className="icon-style da-text-color-primary-1 ms-3 mt-1" style={ { color: "#1677ff" } } onClick={ () => onViewIncentiveEmployments(record) } />
      </Tooltip>
       }
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);
  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    setChildComponent(null)
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {
    setAllData(response);
    setPagination(response.incentives);
    setDataSource(response.incentives.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onEdit = (record) => {
    setChildComponent(<UpdateSaleIncentive onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ false } />);
  };

  const onView = (record) => {
    setChildComponent(<UpdateSaleIncentive onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };

  const onViewIncentiveEmployments = (record) => {
    setChildComponent(<ViewIncentiveEmployees onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };

const onApplyIncentive = (record) => {
    const payload = {
      incentive: record
    }
    makeRequest(setLoader, applyIncentiveReq, payload, getAllData, onError);
  };

  const onDelete = (record) => {
    const payload = {
      filters: `employee_|${record.employment_id}`, id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.incentives.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateSaleIncentive onCompleted={ onCompleted } authUser={ authUser } allData={ allData } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    if(data){
      getAllData()
    }
  
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------
//download sample log
const onDownloadSample = () => {
  makeRequestStateless(downloadSampleReq, 'saleIncentives/downloadSalesSample', onSuccessDownloadSample, onSuccessDownloadSample);
}
const onSuccessDownloadSample = (res, err) => {
  if (res.code === undefined) {
    // Extract file extension
    const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
    const fileName = `sales_sample.${fileExtension}`
    handleDownloadFile(fileName, res)
  }
  else {
    message.error(res.message)
  }
}

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <DownloadButton onClick={ onDownloadSample } />
        <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="saleIncentives/importSalesByBranch" module="Sale Incetives" onCompleted={ () => setChildComponent(null) } />) } />
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />

          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexSaleIncentives;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]

