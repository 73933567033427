import React, { useState } from "react";
import { Form, message, Divider, Upload } from "antd";
import {
  SaveButton, CancelButton, ModalComponent, trans,
  TableComponent, ButtonComponent

} from "@comps/components"
import { makeRequestStateless } from "@utils/helpers";
import { importAttendanceReq } from "../requests";
import { Commons, Const } from "../../constants";
const langKey = Const["attendanceLogs"].lngKey
const formName = "importAttendance";

const ImportAttendance = (props) => {

  const [saving, setSaving] = useState(false)
  const [childComponent, setChildComponent] = useState(null)


  const onSubmit = (data) => {

    let file = null
    if (data.file_attachment === undefined) {
      message.info("Please Select File To Import!")
      return
    }
    if (data.file_attachment !== undefined) {
      file = data.file_attachment.fileList.length > 0 ? data.file_attachment.file : null
    }
    const payload = { file_attachment: file, attendance_log_import: { attachment: { removed: false, org_name: file.name, key: "attachment" } } };
    setSaving(true)
    makeRequestStateless(importAttendanceReq, payload, onSuccess, onError);
  }

  const getMessage=(message)=>{
    if(message.includes("out of bounds")){
      const matches = [...message.matchAll(/\[(.*?)\]/g)];
      if (matches.length>0) {
        const sheetname = matches.map(match => match[1]);
         return "Sheet not found.create or update sheet name to "+ sheetname;
      } else {
          return message;
      }
    }
    return message;
  }
  const errorColumns = [
    {
      key: 'row',
      title: trans(`${langKey}.row`),
      dataIndex: "row",
    },
    {
      key: 'column',
      title: trans(`${langKey}.column`),
      dataIndex: "column",
    },
    {
      key: 'value',
      title: trans(`${langKey}.value`),
      dataIndex: "value",
    },
    {
      key: 'message',
      title: trans(`${langKey}.message`),
      render: (record)=>(
        getMessage(record.message)
       ),
    },
  ]
  const onSuccess = (data, res) => {
    setSaving(false)
    if (data.errors.length === 0) {
      message.success(res.message);
      props.onCompleted(true)
      setChildComponent(null)
    }
    if (data.errors !== undefined && data.errors.length > 0) {
      setChildComponent(
        <>
          <Divider className="text-danger">Importing Errors</Divider>
          <TableComponent
            scroll={ { x: 'max-content' } }
            columns={ errorColumns }
            dataSource={ data.errors }
            pagination={ false }
          />
        </>
      )
    }
  }

  const onError = (err, res) => {
    setSaving(false)
    setChildComponent(null);
    message.error('Server Side Error!');
    if (res.response !== undefined) {
      message.error(res.response.data.message);
    }
  }

  //============uploading file============
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //=========================================

  const footer = [
    <SaveButton form={ formName } key="create_button" htmlType="submit" loading={ saving } />,
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 50 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.import`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>

      <Form name={ formName } onFinish={ onSubmit } scrollToFirstError>

        <Form.Item name="file_attachment" rules={ rules.file_attachment } label={ trans(`${langKey}.file_attachment`) }
          className="da-mb-16"
          size="small"
        >
          <Upload
            accept=".xlsx"
            listType="picture"
            fileList={ fileList }
            onChange={ onChange }
            //onPreview={ onPreview }
            multiple={ false }
            beforeUpload={ (file) => {
              return false;
            } }
          >
            { fileList.length === 1 ? null : <ButtonComponent text="Upload" /> }
          </Upload>
        </Form.Item>
      </Form>

      { childComponent }
    </ModalComponent>
  )
}

export default ImportAttendance

const rules = {
  file_attachment: [
    { required: true, message: trans(`${langKey}.file_attachment`) },
  ],

};