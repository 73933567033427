import React, { useEffect, useState } from "react";
import { Form, Skeleton, message, Row, Col, Spin } from "antd";
import { SaveButton, InputText, CancelButton, ModalComponent, trans, SelectWithSearch,SelectResourceList ,ActionComponent,TableWithFilter} from "@comps/components"
import { makeRequest,makeRequestStateless } from "@utils/helpers";
import { addEmployeesToMachine, deleteMachineUserReq, getEmployeesReq, getLocationsReq, syncUsersReq } from "../requests";
import { Commons, Const } from "../../../constants";
import { createSelectList, createEmploymentResource } from '@comps/commonFunctions';
const langKey = Const["machines"].lngKey
const formName = "createMachine";

const AddMachineUser = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [locations, setLocations] = useState([])
  const [branches, setBranches] = useState([]);
  const [machineTypes, setMachineTypes] = useState([])
  const [branchesByLocation, setBranchesByLocation] = useState([])
  const [locationsWithBranches, setlocationsWithBranches] = useState([])
  const [loading, setloading] = useState(true);
  const [form] = Form.useForm();
  const [errors,setErrors]=useState([]);
  const [machineUsers, setMachineUsers] = useState([])
  const [employments, setEmployments] = useState([])
  useEffect(() => {
    setData()
    getLocations()
    syncUsers()
    getEmployeesByBranch()
    // eslint-disable-next-line
  }, []);

  const getEmployeesByBranch = () => {
    makeRequest(setLoader, getEmployeesReq, props.record.id, onGetEmployees, null);
  }

  const onGetEmployees = (response) => {
    setEmployments(createEmploymentResource(response.employments));
    setloading(false)
  }

  const getLocations = () => {
    const payload = { company_id: 1 }
    makeRequest(setLoader, getLocationsReq, payload, onGetLocations, null);
  }
  const onGetLocations = (response) => {
    setlocationsWithBranches(response.locations)
    setloading(false)
  }
  const setData = () => {
    
    setLocations(createSelectList(props.supportingData.locations));
    setBranches(createSelectList(props.supportingData.branches));
    setMachineTypes(createSelectList(props.supportingData.machine_types));
    setBranchesByLocation(createSelectList(props.supportingData.branches));
    form.setFieldsValue(props.record)
  }
  const onChangeLocation = (id) => {
    form.setFieldsValue({
      branch_id: null
    })
    for (const key in locationsWithBranches) {
      if (locationsWithBranches[key].id === id) {
        setBranchesByLocation(createSelectList(locationsWithBranches[key].branches));
      }
    }
  }
  const onSubmit = (data) => {
    data.id = props.record.id
    const payload = { machine: data,employment_ids:data.employment_ids };
    payload.id = props.record.id
    makeRequest(setLoader, addEmployeesToMachine, payload, onSuccess, onError);
    setSaving(true)
  }

  const onSuccess = (data, res) => {
    setErrors(null)
   if(data.message){
    message.info(data.message)
   }
   let err=false;
   if(data.data && data.data.length && data.data.length>0){
    setErrors(data.data)
    err=true
   }else{
    setErrors(null)
    //message.info("Success");
    //props.onCompleted(data);
   }

   if(!err){
    message.info('success');
   }
    setSaving(false)
   
    syncUsers()
  }

  const syncUsers=()=>{
    message.info('Syncing users...');
    makeRequestStateless(syncUsersReq, props.record.id, onSuccessSync, onError);
  }
  const onSuccessSync = (data, response) => {
    //setSyncing(false)
    message.success(response.message)
    //const users = []
    // data.forEach(element => {
    //   users.push({
    //     label: element.user_name+'-'+element.id,
    //     value: element.id
    //   })
    // });
    setMachineUsers(data)
  }
  const onError = (err, res) => {

    setSaving(false)
    message.info(res.response.data.message);
  }
  const onDelete=(record)=>{
    const ids=[{uid:record.pin_manual}];
    const payload = { user_ids: ids,machine_id:props.record.id};
    payload.id = props.record.id
    makeRequest(setLoader, deleteMachineUserReq, payload, onSuccess, onError);
  }

  const columns = [
    {
      key: 'user_id',
      title: "User Id",
      dataIndex: "pin_manual",
    },
    {
      key: 'user_name',
      title: "User Name",
      dataIndex: "user_name",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
        >
        </ActionComponent>
      ),
      hideFilter: true
    }
  ]
  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent top={ 20 } width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons[props.disabled ? 'view' : 'update']) } subTitle={ trans(`${langKey}.moduleName`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      <Skeleton loading={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form } disabled={ props.disabled }>

          <Row gutter={ 30 }>

          <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="name" rules={ rules.name } label={ trans(`${langKey}.name`) }
                className="da-mb-16"
                size="small" 
              
              >
                <InputText placeholder="Name" disabled={true}/>
              </Form.Item>
            </Col>
            
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="machine_type_id" rules={ rules.machine_type_id } label={ trans(`${langKey}.machine_type_id`) }
                className="da-mb-16"
                size="small"
              
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.machine_type_id`) }
                  options={ machineTypes }
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="location_id" rules={ rules.location_id } label={ trans(`${langKey}.location_id`) }
                className="da-mb-16"
                size="small"
              
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.location_id`) }
                  options={ locations }
                  onChange={ onChangeLocation }
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="branch_id" rules={ rules.branch_id } label={ trans(`${langKey}.branch_id`) }
                className="da-mb-16"
                size="small"
               
              >
                <SelectWithSearch
                  placeholder={ trans(`${langKey}.branch_id`) }
                  options={ branchesByLocation }
                  disabled={true}
                  
                />
              </Form.Item>
            </Col>

  
            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="ip" rules={ rules.ip } label={ trans(`${langKey}.ip`) }
                className="da-mb-16"
                size="small"
               
              >
                <InputText placeholder="IP Address"  disabled={true}/>
              </Form.Item>
            </Col>

            <Col lg={ 12 } sm={ 24 } xs={ 24 }>
              <Form.Item name="udp_port" rules={ rules.udp_port } label={ trans(`${langKey}.udp_port`) }
                className="da-mb-16"
                size="small"
              
              >
                <InputText placeholder="UDP Port" type="number" onWheel={(e) => e.target.blur()}   disabled={true}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="employment_ids" rules={ rules.employment_ids } label={ trans(`${langKey}.employment_ids`) }
                className="da-mb-16"
                size="small" disabled={false}
              >
               <SelectWithSearch mode="multiple" options={employments} placeholder="Select Employees" style={{overflowY:'scroll'}}
                               

                              />
              </Form.Item>
            </Col>

          </Row>

          <SaveButton text="Add Selected Employees To Machine" loading={ saving } form={ formName } key="create_button" htmlType="submit" className="m-1"/>
          <SaveButton className="m-1" text="Sync Users" onClick={syncUsers} loading={ saving } key="create_button"/>
        </Form>
       
      </Skeleton>
      {
       errors && errors.length>0 && <>
        <table className="table border text-danger">
          <thead>
            <tr>
              <th className="text-danger text-center">Errors</th>
            </tr>
          </thead>
          <thead>
           
          </thead>
          <tbody className="text-danger">
          {
            errors.map((error, index) => (
              <tr key={index} >
                <td className="text-danger">{error}</td>
              </tr>
            ))  
           }
          </tbody>
        </table>
        </>
      }

<Spin spinning={loader}>
<div style={ {
  height: "350px",
  overflowY: "scroll"
} }>
  <TableWithFilter
    loader={ loader }
    columns={ columns }
    data={ machineUsers }
  />
</div>
</Spin>

    </ModalComponent>
  )
}

export default AddMachineUser

const rules = {
  name: [
    { required: true, message: trans(`${langKey}.name`) },
  ],
  ip: [
    { required: true, message: trans(`${langKey}.ip`) },
  ],
  location_id: [
    { required: true, message: trans(`${langKey}.location_id`) },
  ],
  branch_id: [
    { required: true, message: trans(`${langKey}.branch_id`) },
  ],
  udp_port: [
    { required: true, message: trans(`${langKey}.udp_port`) },
  ],
  machine_type_id: [
    { required: true, message: trans(`${langKey}.machine_type_id`) },
  ],
  employment_ids: [
    { required: true, message: trans(`${langKey}.employment_ids`) },
  ],

};

const formLayout = {
  layout: "vertical",
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
  labelAlign: "left"
}