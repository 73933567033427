import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const } from "../constants";
import { message ,Tooltip} from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import CreateAttendancePolicyPlanner from "./components/CreateAttendancePolicyPlanner";
import ViewAttendancePolicyPlan from "./components/ViewAttendancePolicyPlan";
import moment from "moment";
import { UserOutlined } from "@ant-design/icons";

const langKey = Const["attendancePolicyPlanner"].lngKey;

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["attendancePolicyPlanner"].route
      }
    ]
  }
}
//========================================
const IndexAttendancePolicyPlanner = (props) => {
  const totalRecords=0;
  const [allData, setAllData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState();
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'name',
      title: trans(Commons.name),
      dataIndex: "name",
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render:(record)=>(
        moment(record.date).format('YYYY-MM-DD')
      ),
    },


    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
          onView={ onView }
        >
           <Tooltip title='Add Employees'>
              <UserOutlined className="icon-style da-text-color-info-1 ms-3" style={ { color: "#1677ff" } } onClick={ () => onAddEmployee(record) } />
            </Tooltip>
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setAllData(response)
    setPagination(response.policy_planers);
    setDataSource(response.policy_planers.data);
  };

  const onEdit = (record) => {
    setChildComponent(
      // <UpdateAbsentPolicy onCompleted={ onCompleted } record={ record } allData={allData} disabled={ false } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <ViewAttendancePolicyPlan onCompleted={ onCompleted } record={ record } allData={ allData } disabled={ true } />
    );
  };
  const onAddEmployee = (record) => {
    setChildComponent(
      <ViewAttendancePolicyPlan onCompleted={ onCompleted } record={ record } allData={ allData } disabled={ true } addEmployees={true}/>
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.policy_planers.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateAttendancePolicyPlanner onCompleted={ onCompleted } allData={ allData } onSuccess={ onSuccess } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>

          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexAttendancePolicyPlanner;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]

/*
https://demo-api.klok.ai/api/v1/attendancePolicyPlanners/preview?
request_policy_planner[company_id]: 1
request_policy_planner[name]: test
request_policy_planner[date]: 2023-11-01T00:00:00.000Z
request_policy_planner[travel_policy_id]: 1
request_policy_planner[employment_ids][]: 8bee1051-efc7-49fa-bdad-faa2e0c2f030
request_policy_planner[employment_ids][]: db969bd4-205e-4d9b-a3f0-1b1f4f011787
*/