import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent, ButtonComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { message, Badge, Popconfirm, Tooltip } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { closeFinalSettleReq, downloadFinalSettlePayslipReq, getAllDataReq, getFilterReq, getPayslipByFinalSettleId, regeneratePayslipReq } from "./requests";
import UpdateEndOfEmployment from "@mods/EOEServiceManagement/endOfEmployments/components/UpdateEndOfEmployment";
import { CustomButton } from "@comps/components";
import { handleDownloadFile } from "@utils/helpers";
import { StopOutlined, WarningOutlined } from "@mui/icons-material";
import ViewPayslip from "../companyPayslips/components/ViewPayslip";

const langKey = Const["finalSettlement"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["finalSettlement"].route
      }
    ]
  }
}

const IndexFinalSettlement = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState('');
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'employment',
      title: trans(Commons.employee),
      render: (record) => (
        record.requestor ? record.requestor.full_name_code : null
      )
    },
    {
      key: trans(Commons.branch),
      title: trans(Commons.branch),
      render: (record) => {
        return record.requestor.branch_name
      }
    },
    {
      key: trans(Commons.location),
      title: trans(Commons.location),
      render: (record) => {
        return record.requestor.location_name
      }

    },
    {
      key: trans(Commons.department),
      title: trans(Commons.department),
      render: (record) => {
        return record.requestor.department_name
      }
    },
    {
      key: trans(Commons.designation),
      title: trans(Commons.designation),
      render: (record) => {
        return record.requestor.designation_name
      }
    },
    {
      key: 'status',
      title: trans(Commons.status),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          //onEdit={ onEdit }
          onView={ onView }
        //onDelete={ onDelete }
        >
          { record.status === 'Pending' && <CustomButton onClick={ () => onRegenerate(record) } text="Regenrate" /> }
          <Popconfirm title="Are you sure to close?" icon={ <WarningOutlined /> } onConfirm={ () => onClose(record) }>
            { record.status === 'Pending' && <Tooltip title="Close"><ButtonComponent className="text-warning" icon={ <StopOutlined /> }></ButtonComponent></Tooltip> }


          </Popconfirm>
          <CustomButton text="Payslip" onClick={ () => getPaylip(record, 'view') } />
          {/* <CustomButton text="Payslip" onClick={ () => getPaylip(record, 'download') } loading={ loader } type='primary' /> */ }
        </ActionComponent>
      ),
    },
  ];
  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);
  const getAllData = () => {
    setChildComponent(null)
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response, data) => {
    setAllData(response);
    setPagination(response.final_settlements);
    setDataSource(response.final_settlements.data);
    message.info(data.message)
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }

  const onView = (record) => {
    setChildComponent(<UpdateEndOfEmployment onCreated={ onCompleted } record={ record } disabled={ true } />);
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }
  //complete final settle
  const onClose = (record) => {
    makeRequest(setLoader, closeFinalSettleReq, record.id, onSuccess, onError);
  };
  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };
  //re-calculate payslip
  const onRegenerate = (record) => {
    makeRequest(setLoader, regeneratePayslipReq, record.id, onSuccess, onError);
  }

  const getPaylip = (record, action) => {
    if (action === 'download') {
      onDownloadPayslip(record);
    } else {
      makeRequest(setLoader, getPayslipByFinalSettleId, record.id, onSuccessPayslip, onError);
    }
  }
  const onSuccessPayslip = (response, data) => {
    if (response.payslip) {
      setChildComponent(<ViewPayslip onCompleted={ onCompleted } record={ response.payslip } allData={ allData } disabled={ true } finalSettleId={ response.payslip.end_of_employment_id } />);
    }
  }
  const onDownloadPayslip = (record) => {
    makeRequest(setLoader, downloadFinalSettlePayslipReq, record.id, onDownloadSuccess, onDownloadSuccess);
  }

  const onDownloadSuccess = (err, res) => {
    setLoader(false)
    if (res.code === undefined) {
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `paysilp.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <CreateButton text='Refresh' onClick={ getAllData } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexFinalSettlement;
const filterFields = [
  {
    type: "text",
    placeholder: "Name",
    key: "name"
  },
]

