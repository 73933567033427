import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  ButtonComponent,
  trans,
  HeaderComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { Badge, message, Space } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { downloadPayslipReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";

import { DownloadOutlined } from "@ant-design/icons";
import ViewMyPayslip from "./components/ViewMyPayslip";
import dayjs from 'dayjs'
import { handleDownloadFile } from "@utils/helpers";
import ViewPayslip from "../companyPayslips/components/ViewPayslip";

const langKey = Const["myPayslip"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["myPayslip"].route
      }
    ]
  }
}

const IndexMyPayslip = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: "id",
    sortType: "desc",
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'full_name_code',
      title: trans(`${langKey}.full_name_code`),
      render: (record) => {
        return <div>{ record.requestor.full_name }<br /><small>{ record.requestor.emp_code }</small></div>
      },
    },
    {
      key: 'month',
      title: trans(`${langKey}.month`),
      render: (record) => (
        dayjs(record.month).format('YYYY MMMM')
      )
    },
    {
      key: 'net_earning',
      title: trans(`${langKey}.net_earning`),
      render: (record) => (
        record.net_earning && 'Rs ' + record.net_earning
      )
    },
    {
      key: 'net_deduction',
      title: trans(`${langKey}.net_deduction`),
      render: (record) => (
        record.net_deduction && 'Rs ' + record.net_deduction
      )

    },
    {
      key: 'net_reimbursement',
      title: trans(`${langKey}.net_reimbursement`),
      render: (record) => (
        record.net_reimbursement && 'Rs ' + record.net_reimbursement
      )
    },
    {
      key: 'net_salary',
      title: trans(`${langKey}.net_salary`),
      render: (record) => (
        record.net_salary && 'Rs ' + record.net_salary
      )
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          // onEdit={ onEdit }
          onView={ onView }
        >
          <Space>
          <ButtonComponent text="Payslip" onClick={ () => onViewPayslip(record) } />
            {/* <ButtonComponent onClick={ () => onDownload(record) } icon={ <DownloadOutlined /> } loading={ loading } type='primary' /> */}
          </Space>
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================
  
  useEffect(() => {
    onOpenFilter()
    // eslint-disable-next-line
  }, []);

  const getAllData = (filters) => {
    setFilters(filters)
    setChildComponent(null)
    const payload = {
      page: 1,
      filters: `employees_|${authUser.employment_id};${filters}`
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
    onCloseFilter()
  };
  const onSuccess = (response) => {

    setAllData(response);
    //setTotalRecords(response.payslips.data_key.current_page);
    setDataSource(response.payslips.data);
    onCloseFilter()
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
    onCloseFilter()
  }
  const onEdit = (record) => {
    //setChildComponent(<UpdateSaleIncentive onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ false } />);
  };
  const onView = (record) => {
    setChildComponent(<ViewMyPayslip onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };
  const onViewPayslip = (record) => {
    setChildComponent(<ViewPayslip onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };
  const onDownload = (record) => {
    const payload = {
      filters: `${filters}ids_|${record.id};download_type_|pdf;`,
    }
    makeRequest(setLoader, downloadPayslipReq, payload, onDownloadSuccess, onDownloadSuccess);
    //makeRequestStateless(downloadPayslipReq, payload, onDownloadSuccess, onDownloadSuccess);
  }

  const onDownloadSuccess = (err, res) => {
    setLoading(false)
    if (res.code === undefined) {
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `my_payslip.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } onFilter={ getAllData } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <ButtonComponent text='Refresh' type='primary' onClick={ () => getAllData(filters !== undefined ? filters : '') } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
    </>
  );
};

export default IndexMyPayslip;
const filterFields = [
  // {
  //   type: "select",
  //   placeholder: 'Payroll',
  //   key: "payroll",
  //   data_key: "supporting_data.payroll",
  //   //is_resource: true
  // },
  {
    type: "date",
    placeholder: "Month",
    key: "month"
  },

]

