import { get, del, post, postFd, download, put } from "@utils/axios";

const api = "machines";

export const getAllDataReq = (payload) => {
  return get(`${api}?page=${payload.page}&filters=${payload.filters}`)
};

export const createDataReq = (payload) => {
  return post(`${api}?`, payload);
};

export const updateDataReq = (payload) => {
  return put(`${api}/${payload.id}`, payload);
};

export const deleteDataReq = (id) => {
  return del(`${api}/${id}`);
};
export const deactivateMachineReq = (id) => {
  return get(`${api}/deactivteMachine/${id}`);
};
export const getLocationsReq = (payload) => {
  return post(`${api}/getLocations`, payload);
};
export const getEmployeesReq = (machineId) => {
  return get(`${api}/getEmployments/${machineId}`);
};

export const getStatusReq = (id) => {
  return get(`${api}/getStatus/${id}`);
};

//mapping
export const addUserEmpReq = (payload) => {
  return post(`${api}/${payload.machineId}/update-mapped-employees`, payload);
};
export const getMachineByIdReq = (id) => {
  return get(`${api}/${id}`);
};
export const syncUsersReq = (id) => {
  return get(`${api}/sync/${id}`);
};
export const downloadSampleMappingReq = (id) => {
  return download(`${api}/mapper-import-sample?machine_id=${id}`);
};
export const importMapperReq = (payload) => {
  return postFd(`${api}/mapper-import`, payload);  //attachment: (binary)mapper_import: { }  machine_id: 2
};


export const getFilterReq = (payload) => {
  return get(`${api}?page=1&filters?company_id=1&fields=page=1&filters=organization_structure_fields_|company;preset_|grade;`)
}

//add employees to machine
export const addEmployeesToMachine = (payload) => {
  return post(`${api}/add-users/${payload.id}`, payload);
};
export const deleteMachineUserReq = (payload) => {
  return post(`${api}/delete-users/${payload.id}`, payload);
};