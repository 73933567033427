import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  ButtonComponent,
  trans,
  HeaderComponent,
  EmployeeWithProfile, PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { Badge, message, Space } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { downloadPayslipReq, getAllDataReq, getFilterReq, regeneratePayslipReq } from "./requests";
import { useSelector } from "react-redux";
import ViewMyPayslip from "./components/ViewMyPayslip";
import dayjs from 'dayjs'
import { handleDownloadFile } from "@utils/helpers";
import { CalculateSharp } from "@mui/icons-material";
import ViewPayslip from "./components/ViewPayslip";

const langKey = Const["myPayslip"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.companyModuleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.companyModuleName`),
        path: Const["myPayslip"].route
      }
    ]
  }
}

const IndexCompanyPayslip = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [allData, setAllData] = useState({});
  const [filters, setFilters] = useState();
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'full_name_code',
      title: trans(`${langKey}.full_name_code`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'month',
      title: trans(`${langKey}.month`),
      render: (record) => (
        dayjs(record.month).format('YYYY MMMM')
      )
    },
    {
      key: 'net_earning',
      title: trans(`${langKey}.net_earning`),
      render: (record) => (
        record.net_earning && 'Rs ' + record.net_earning
      )
    },
    {
      key: 'net_deduction',
      title: trans(`${langKey}.net_deduction`),
      render: (record) => (
        record.net_deduction && 'Rs ' + record.net_deduction
      )

    },
    {
      key: 'net_reimbursement',
      title: trans(`${langKey}.net_reimbursement`),
      render: (record) => (
        record.net_reimbursement && 'Rs ' + record.net_reimbursement
      )
    },
    {
      key: 'net_salary',
      title: trans(`${langKey}.net_salary`),
      render: (record) => (
        record.net_salary && 'Rs ' + record.net_salary
      )
    },
    {
      key: 'is_final_settlement',
      title: trans(`${langKey}.is_final_settlement`),
      render: (record) => (
        record.is_final_settlement ? 'Yes' : 'No'
      )
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onEdit={ !record.is_completed && onEdit }
          onView={ onView }
        >
          <Space>
            { !record.is_completed && <ButtonComponent onClick={ () => onRegenerate(record) } icon={ <CalculateSharp /> } /> }

            <ButtonComponent text="Payslip" onClick={ () => onViewPayslip(record) } />
            {/* <ButtonComponent onClick={ () => onDownload(record) } icon={ <DownloadOutlined /> } loading={ loading } type='primary' /> */ }
          </Space>
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData(filters)
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = (filters) => {
    if (dataSource.length < 1) {
      onOpenFilter()
    }
    setFilters(filters)
    setChildComponent(null)
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
    onCloseFilter()
  };
  const onSuccess = (data, response) => {
    setAllData(response);
    //setTotalRecords(response.payslips.data_key.current_page);
    setDataSource(response.data.payslips.data);
    setPagination(response.data.payslips);
    if (response.message !== undefined) {
      message.info(response.message);
    }
    onCloseFilter()
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
    onCloseFilter()
  }

  const onEdit = (record) => {
    setChildComponent(<ViewMyPayslip onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ false } />);
  };
  const onView = (record) => {
    setChildComponent(<ViewMyPayslip onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };
  const onViewPayslip = (record) => {
    setChildComponent(<ViewPayslip onCompleted={ onCompleted } record={ record } authUser={ authUser } allData={ allData } disabled={ true } />);
  };

  const onDownload = (record) => {
    const payload = {
      filters: `${filters}ids_|${record.id};download_type_|pdf;`,
    }
    makeRequest(setLoader, downloadPayslipReq, payload, onDownloadSuccess, onDownloadSuccess);
    //makeRequestStateless(downloadPayslipReq, payload, onDownloadSuccess, onDownloadSuccess);
  }

  const onDownloadSuccess = (err, res) => {
    setLoading(false)
    if (res.code === undefined) {
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `paysilp.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  //re-calculate 
  const onRegenerate = (record) => {
    makeRequest(setLoader, regeneratePayslipReq, record.id, onSuccess, onError);
    //makeRequestStateless(downloadPayslipReq, payload, onDownloadSuccess, onDownloadSuccess);
  }

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  const onCompleted = (response) => {
    if (response) {
      setDataSource(response.data.payslips.data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ getAllData } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);
  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <ButtonComponent text='Refresh' type='primary' onClick={ () => getAllData(filters !== undefined ? filters : '') } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
        <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
      </BodyComponent>
    </>
  );
};

export default IndexCompanyPayslip;
const filterFields = [

  {
    type: "select",
    placeholder: 'Payroll',
    key: "payroll",
    data_key: "supporting_data.payroll",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: 'Employee',
    key: "employee",
    data_key: "supporting_data.employments",
    resource_type: 'employment'
    //is_resource: true
  },
  {
    type: "date",
    placeholder: "Month",
    key: "month"
  },

]

