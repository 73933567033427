import { useState, useEffect } from "react";
import {
  BodyComponent,
  trans,
  HeaderComponent,
  PaginationComponent,
  ImportButton,
  CreateButton,RefreshButton,
  DeleteButton,
  DownloadButton
} from "@comps/components";
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { Const } from "../constants";
import { Button, message, Popconfirm, Popover, Row, Col, Spin, Tooltip } from 'antd';
import { FilterComponent, FilterButton, SelectWithSearch } from "@comps/components";
import { downloadSampleReq, getAllDataReq, getFilterReq, getTotalHoursReq } from "./requests";
import CreateCompanyRoaster from "./components/CreateCompanyRoaster";
import moment from "moment";
import { LeftOutlined, RightOutlined, WarningOutlined } from "@ant-design/icons";
import DeleteCompanyRoaster from "./components/DeleteCompanyRoaster";
import { deleteMultipleShiftsReq, deleteShiftReq } from "../myRoster/requests";
import CreateShift from "../myRoster/components/CreateShift";
import UpdateShift from "../myRoster/components/UpdateShift";
import "../common/Roster.css"
import { convertMinutesToHours, convertTimeTotz, createEmploymentResource, getResourceDataReq, checkPermissions } from '@comps/commonFunctions';
import { getDatesFilters, getOrganizationFilters } from "../../common/commonFilterFields";
import ImportSample from "../../common/ImportSample";
import { pusherInitializer, decryptLocalData, lightenColor } from "@comps/commonFunctions";
import { keyframes } from "@mui/material";
import { Icon } from '@iconify/react';
import { useLocation } from "react-router-dom";

const langKey = Const["companyRoaster"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["companyRoaster"].route
      }
    ]
  }
}

var multipleShiftIds = [];

const IndexCompanyRoaster = (props) => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState('');
  const [employments, setEmployments] = useState([]);
  const [dateTotalSource, setDateTotalSource] = useState([]);
  const [empTotal, setempTotal] = useState({});
  const [shifts, setShifts] = useState([])
  const [fromDate, setFromDate] = useState()
  const [toDate, setToDate] = useState()
  const [editModal, setEditModal] = useState(null);
  const [loading, setloading] = useState(true)
  const [defaultEmployments, setDefaultEmployments] = useState([])
  const [employees, setEmployees] = useState([])
  const [multipleEdit, setMultipleEdit] = useState(false)
  const [selectedShiftIds, setSelectedShiftIds] = useState([]);
  const [rerender, setRerender] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [employmentId,setEmploymentId]=useState('');
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });


  // ... (other code)
  // ==================================
  //   Use States End
  // ==================================

  // ================================== 
  //   Table Data End
  // ==================================
  //==========================Start Pusher=================================
  useEffect(() => {
    const pusher = pusherInitializer();
    const channel = pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('shift-creation-errors-event', function (data) {

      getAllData();
      getEmployments();
    });
    // Cleanup function for unsubscribing and unbinding when the component is unmounted
    return () => {
      // Unbind the event handler
      channel.unbind('shift-creation-errors-event');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-line
  });


  useEffect(() => {
    const pusher = pusherInitializer();
    const channel = pusher.subscribe(decryptLocalData('user').tenant);
    channel.bind('shifts-created-event', function (res) {
      getAllData();
      getEmployments();
    });
    // Cleanup function for unsubscribing and unbinding when the component is unmounted
    return () => {
      // Unbind the event handler
      channel.unbind('shifts-created-event');
      // Unsubscribe from the channel
      pusher.unsubscribe(decryptLocalData('user').tenant);
      // Disconnect from Pusher
      pusher.disconnect();
    };
    // eslint-disable-next-line
  });
  //==========================end Pusher =================================

  useEffect(() => {
    // Trigger your logic here on route change
    getAllData();
    getEmployments()
    setMultipleEdit(false)
    // eslint-disable-next-line
  }, [location.pathname, filters, currentPage]);

  useEffect(() => {
    setFilters('')
    // eslint-disable-next-line
  }, [props]);

  // ==================================
  //   Table Data End
  // ==================================
  const getEmployments = (id) => {

    if(props.filters){
      props.filters.replace('employees_|undefined;','')
    }
    let payload = {
      page: currentPage,
      type: 'employment',
      filters: `search_|${id};${props.filters}`
    }
    makeRequestStateless(getResourceDataReq, payload, onSuccessResourse, null);
  };

  const onSuccessResourse = (response) => {
    setEmployees(response.resources.data)
    //setEmployments(response.resources.data);
    //setPagination(response.resources.meta.pagination)
    if (defaultEmployments.length === 0) {
      setDefaultEmployments(response.employee_employments.data)
    }
  }

 const onChangeEmployment=(id)=>{
    //setEmploymentId(id)
    getAllData(id)
 }

  const getAllData = (id) => {
    let filt=filters;
    if(filters){
      filt=filters.replace('employees_|undefined;','')
    }
    let pfilter=props.filters;
    if(props.filters){
      pfilter=props.filters.replace('employees_|undefined;','')
    }

    setloading(true)
    multipleShiftIds = [];
    setSelectedShiftIds([]);
    const payload = {
      page: currentPage,
      filters: `${pfilter};${filt}`
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onErrorCreatingRoaster);
  };
  const onSuccess = (response) => {

    setPagination(response.pagination);
    setChildComponent(null);
    setEditModal(null)
    setEmployments(response.employments);
    setFromDate(response.from_date)
    setToDate(response.to_date)

    //   Object.values(response.shifts).forEach(each => {
    //     each[0]['selected']=false;
    // });

    setShifts(response.shifts)

    let empids = ''
    response.employments.forEach((each) => {
      empids += `${each.id},`
    })
    const payload = {
      page: currentPage,
      filters: `${props.filters};${filters}`,
      empIds: empids
    }
    makeRequest(setLoader, getTotalHoursReq, payload, onGetTotalHours, null);
  };
  const onErrorCreatingRoaster = (err, res) => {
  }
  const onGetTotalHours = (response) => {
    setDateTotalSource(response.date_total)
    setempTotal(response.emp_total)
    // setLoader(false)
    setloading(false)
  }

  const cancel = (e) => {

  };
  const onDeleteShift = (shift) => {
    makeRequest(setLoader, deleteShiftReq, shift.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    message.success("Record Deleted")
    getAllData();
  };
  const onError = (err, res) => {
    message.info(res.data.message);
    getAllData();
    //setErrors(errorList);
  }

  // Create component modal 
  const onDeleteAll = () => {
    setChildComponent(<DeleteCompanyRoaster onCreated={ onCreated } employments={ employments } page={ currentPage } />);
  };
  const onCreate = () => {
    setChildComponent(<CreateCompanyRoaster onCreated={ onCreated } onSuccess={ onSuccess } page={ currentPage } />);
  };

  const onCreated = (data) => {
    if (data) {
      setEmployments(data.employments);
      setShifts(data.shifts)
      getAllData()
    }

    setChildComponent(null);
    setEditModal(null)
  };
  const onCreateSingleShift = (date, employment_id) => {
    setEditModal(
      <CreateShift onCreated={ onCreated } date={ date } employment_id={ employment_id } employments={ employments } getAllData={ getAllData } filters={ `${props.filters};${filters}` } page={ currentPage } />
    );
  }

  const onEnableDisableMultiple = () => {
    setMultipleEdit(!multipleEdit);
    Object.values(shifts).forEach(each => (
      each[0]['selected'] = false
    ));
    multipleShiftIds = [];
    setSelectedShiftIds([]);
    setShifts(shifts)
  }
  const onEditSingleShift = (shift) => {
    setRerender(!rerender)
    if (multipleEdit) {
      const exists = multipleShiftIds.includes(shift.id);
      if (exists) {
        multipleShiftIds = multipleShiftIds.filter(el => el !== shift.id);
      }
      else {
        multipleShiftIds.push(shift.id)
      }
      Object.values(shifts).forEach(each => (
        each[0]['selected'] = multipleShiftIds.includes(each[0]['id'])
      ));
      setSelectedShiftIds(multipleShiftIds);
      setShifts(shifts)
    }
    else {
      setEditModal(
        <UpdateShift onCreated={ onCreated } shift={ shift } getAllData={ getAllData } multiple={ false } filters={ `${props.filters};${filters}` } page={ currentPage } />
      );
    }
  }

  const onEditMultipleShift = () => {
    setEditModal(
      <UpdateShift onCreated={ onCreated } shift={ null } getAllData={ getAllData } multiple={ true } edit={ true } shiftIds={ selectedShiftIds } filters={ `${props.filters};${filters}` } page={ currentPage } />
    );
  }
  const onDeleteMultipleShifts = () => {
    let payload = {
      shift: selectedShiftIds
    }
    makeRequest(setLoader, deleteMultipleShiftsReq, payload, onDeleted, onError);
  }


  const onViewSingleShift = (shift) => {
    setEditModal(
      <UpdateShift onCreated={ onCreated } shift={ shift } getAllData={ getAllData } disabled={ true } filters={ `${props.filters};${filters}` } page={ currentPage } />
    );
  }

  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleReq, null, onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `roster_sample_import.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }

  const actions = (date, employment_id, shift) => {
    return (
      <div>
        { shift ?
          <>
            <div style={ { display: 'flex', flexDirection: 'column' } }>

              <Button style={ { border: 'none' } } icon={ <EditOutlined style={ { color: '#14AE5C' } } /> } onClick={ () => onEditSingleShift(shift) } disabled={!checkPermissions(null,'update')}>Edit</Button>
              <Button style={ { border: 'none' } } icon={ <EyeOutlined style={ { color: '#4079FC' } } /> } onClick={ () => onViewSingleShift(shift) } disabled={!checkPermissions(null,'view')}>View</Button>
              <Popconfirm style={ { width: 590, height: 140 } }
                icon={ <WarningOutlined style={ { color: 'red' } } /> }
                title="Delete"
                description="Are you sure you want to delete this.?"
                onConfirm={ () => onDeleteShift(shift) }
                onCancel={ cancel }
                Text={ <Button style={ { border: 'none', background: "none", color: "white", width: 71, height: 58 } }>Delete</Button> }
                cancelText={ <div style={ { border: 'none', background: "none", color: "#4079DF", width: 71, height: 58 } }>Cancel</div> }
              >
                <Button style={ { border: 'none' } } icon={ <DeleteOutlined style={ { color: '#EA4335' } } /> } disabled={!checkPermissions(null,'delete')}>Delete</Button>
              </Popconfirm>
              {/* <Button style={ { border: 'none' } } icon={ <CloseOutlined /> }>Close</Button> */ }
            </div>
          </>
          :
          <div style={ { display: 'flex', flexDirection: 'row' } }>
            <Button style={ { border: 'none' } } icon={ <PlusOutlined className="text-primary" /> } onClick={ () => onCreateSingleShift(date, employment_id) } disabled={!checkPermissions(null,'create')}>Shift</Button>
          </div>
        }
      </div>
    );
  }
  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);
  }
  //----end filter--------------------
  const onClickBackDate = (start, end) => {
    setloading(true)
    const from = moment(start).subtract(1, 'months').format('YYYY-MM-DD');
    const to = moment(end).subtract(1, 'months').format('YYYY-MM-DD');
    const payload = `${filters};from_|${from};to_|${to};`

    setFilters(payload)
  }

  const onClickNextDate = (start, end) => {
    setloading(true)
    const from = moment(start).add(1, 'months').format('YYYY-MM-DD');
    const to = moment(end).add(1, 'months').format('YYYY-MM-DD');
    const payload = `${filters};from_|${from};to_|${to};`
    setFilters(payload)
  }

  const filterArray = getOrganizationFilters('')

  filterArray.push({
    type: "select",
    placeholder: trans(`${langKey}.time_slot_id`),
    key: "time_slot",
    data_key: "supporting_data.time_slot",
    //is_resource: true
  },
  )

  if (props.module !== 'myRoaster') {
    filterArray.unshift({
      type: "select",
      placeholder: 'Employee',
      key: "employees",
      data_key: "supporting_data.employments",
      resource_type: 'employment'
      //is_resource: true
    },
    )
  }

  const filterFields = filterArray.concat(getDatesFilters(''))

  const onRefresh=()=>{
    getAllData()
    setloading(true)
    getEmployments()
  }
  return (
    <>
      
      { childComponent }
      <BodyComponent>

        <HeaderComponent className="header-component" headers={ pageConfig.headers }>
        <RefreshButton onClick={ onRefresh } />
          {
            
            props.module !== 'myRoaster' && <>
              <DeleteButton onClick={ onDeleteAll } disabled={!checkPermissions(null,'delete')}/>
              <DownloadButton onClick={ onDownloadSample } />
              <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="roster/import" module="Roaster" onCompleted={ () => setChildComponent(null) } />) } disabled={!checkPermissions(null,'create')} />
              <CreateButton onClick={ onCreate } disabled={!checkPermissions(null,'create')}/>
            </>
          }

          {
            props.module !== 'myRoaster' && <FilterButton onClick={ onOpenFilter } />
          }
          {/* <MultiselectButton className="multiselect-button" />
          <ImportButton className="import-button" style={ { position: 'absolute', top: 60, right: 172 } } /> */}


        </HeaderComponent>

        <div style={ {
          display: 'flex',
          justifyContent: 'right',
          marginTop: '1%',
          marginBottom: '2%'
        } }>
          { multipleEdit && selectedShiftIds.length > 0 &&
            <>
              <Popconfirm style={ { width: 590, height: 140 } }
                icon={ <WarningOutlined style={ { color: 'red' } } /> }
                title="Delete"
                description="Are you sure to delete selected shifts?"
                onConfirm={ () => onDeleteMultipleShifts() }
                onCancel={ cancel }
                okText="Yes"
                cancelText="No"
              >
                <DeleteButton disabled={!checkPermissions(null,'delete')} style={ {
                  marginRight: 10
                } } text={ "Delete Multiple" } />
              </Popconfirm>

              <CreateButton disabled={!checkPermissions(null,'create')} style={ {
                marginRight: 10
              } } onClick={ () => onEditMultipleShift() } text={ "Edit Multiple" } className={ "bg-info" } />
              </>
               }
               {checkPermissions(props.modkey,'create') && <CreateButton onClick={ onEnableDisableMultiple } text={ multipleEdit ? "Disable Multiselect" : "Enable Multiselect" } className={ multipleEdit ? 'bg-danger' : "bg-success" } />}
          
        </div>


        <div style={ {
          marginTop: '2%'
        } }>
          {/* <Spin spinning={ loader }> */ }
          { editModal }
          <div style={ {
            background: '#FFFFFF',
            paddingTop: 30,
            border: '1px solid #C9D6DF',
            borderRadius: 10,
          } }>
            <Spin spinning={ loading } tip="Loading">
              <Row>
                {/* <Col span={ 5 } style={ {
                  paddingLeft: 20,
                } }>
                  { props.module !== 'myRoaster' &&
                    <SelectWithSearch className="search-employee"
                      loading={ loader }
                      style={ { minWidth: 282, borderRadius: 5, background: '#FFFFFF' } }
                      placeholder="Search"
                      size='large'
                      options={ createEmploymentResource(employees) }
                      onChange={onChangeEmployment}
                      onSearch={ (id) => getEmployments(id) }
                    />
                  }
                </Col> */}
                <Col span={ 19 } style={ {
                  textAlign: 'left',
                  paddingBottom: 20,
                  fontSize: 20,
                  marginLeft:20
                } }>

                  <Tooltip placement="top" title="Previous Month">
                    <Button style={ { color: '#000', border: 'none', marginLeft: -15, height: 20, background: 'none' } } icon={ <LeftOutlined /> } onClick={ () => onClickBackDate(fromDate, toDate) } />
                  </Tooltip>
                  <span style={ {
                    color: "#000",
                    fontWeight: "bold",
                    marginLeft: 10,
                    marginRight: 10,
                  } }>
                    { moment(fromDate).format('DD MMMM YYYY') } { " - " }
                    { moment(toDate).format('DD MMMM YYYY') }
                  </span>
                  <Tooltip placement="top" title="Next Month">
                    <Button style={ { color: "#000", border: 'none', height: 20, background: 'none' } } icon={ <RightOutlined /> } onClick={ () => onClickNextDate(fromDate, toDate) } />
                  </Tooltip>
                </Col>
              </Row>
              <Row>
                <Col span={ 5 } style={ {
                  paddingTop: 51,
                } }>
                  <table className="table">
                    <tbody>
                      { employments.map((employee, key) => (
                        <tr>
                          <td key={ keyframes } style={ {
                            width: 250, overflowX: "hidden", padding: 0,
                            borderBottom: '1px solid #f2f2f2',
                          } } >
                            <div style={ {
                              height: 100,
                              overflow: "hidden",
                              display: 'flex',
                              flexDirection: 'row',
                              padding: 10,
                            } }>
                              <div>
                                <img src={ employee.profile_pic_url !== "images/arnold-avatar.jpg" ? employee.profile_pic_url : "/images/arnold-avatar.jpg" } width={ 45 } height={ 45 } style={ { borderRadius: '60px' } } alt="" />
                              </div>
                              <div className="each-employment">
                                <strong className="employee-full-name"> { employee.full_name } </strong>
                                <p className="employee-code">{ employee.code }</p>
                              </div>
                              <div className="each-employment-hour">
                                <p>{ empTotal && empTotal[employee.id] !== undefined ? convertMinutesToHours(parseFloat(empTotal[employee.id])) : '' }</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )) }
                    </tbody>
                  </table>
                </Col>
                <Col span={ 19 } style={ {
                  overflowX: "scroll",
                } }>
                  <table className="table" >
                    <thead>
                      <tr>
                        { Object.keys(dateTotalSource).map((date, mins) => (

                          <th style={ { border: '1px solid #f2f2f2', padding: 14, } }>
                            <div style={ {
                              textAlign: 'center',
                              color: "#4079FC",
                            } }>
                              <span style={ { marginRight: '2px' } }>{ moment(date).format('dddd').substring(0, 10) }</span>
                              <span>{ moment(date).format('DD') }</span>
                            </div>
                          </th>
                        )) }
                      </tr>
                    </thead>
                    <tbody>
                      {

                        employments.map((employee) => (
                          <tr>
                            {
                              Object.keys(dateTotalSource).map((date, mins) => (
                                <>
                                  <td style={ {
                                    padding: 0, margin: 0, border: `1px solid #f2f2f2`
                                  } }>
                                    <div style={ {
                                      width: 150,
                                      height: 100,
                                      overflow: "hidden",
                                    } }>
                                      { multipleEdit ?
                                        <div style={ {
                                          margin: 'auto',
                                          textAlign: 'center',
                                          padding: 8
                                        } }>

                                          <Button
                                            style={ {
                                              backgroundColor:
                                                shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id) &&
                                                  shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0] ?
                                                  (shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['selected']
                                                    ? lightenColor('#007508', 50)
                                                    : lightenColor(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['bg_colour'], 70)) :
                                                'rgba(234, 67, 53, 0.10)',
                                              color:
                                                dateTotalSource[date] === 0 ||
                                                  shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id)
                                                  ? ''
                                                  : '#687980',
                                              margin: 'auto',
                                              width: '100%',
                                              height: 80,
                                              padding: 0,
                                              border: 'none',
                                            } }
                                          >
                                            { dateTotalSource[date] === 0 ? (
                                              <span style={ { color: "#EA4335" } }>Day Off</span>) :
                                              shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id) ? (
                                                <div onClick={ () => onEditSingleShift(shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id) ?
                                                  shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0] : null) }  >
                                                  <div style={ {
                                                    position: 'absolute',
                                                    left: 4,
                                                  } }>
                                                    { shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['selected'] ? <Icon icon="subway:tick" /> : <Icon icon="maki:cross" /> }

                                                  </div>
                                                  <div style={ {
                                                    color: '#000',
                                                    fontSize: 16,
                                                  } } >
                                                    { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['start']).format('hh:mm') } { " - " }
                                                    { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['end']).format('hh:mm') }
                                                  </div>
                                                  <div style={ {
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    paddingRight: 20,
                                                    paddingLeft: 20,
                                                    marginTop: 10,

                                                  } }>
                                                    <div>
                                                      { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['start']).format('A') === "AM" ?
                                                        <Icon style={ {
                                                          fontSize: 20,
                                                        } } icon="line-md:sunny-outline-to-moon-alt-loop-transition" />
                                                        :
                                                        <Icon style={ {
                                                          fontSize: 20,
                                                        } } icon="line-md:moon-to-sunny-outline-loop-transition" />
                                                      }
                                                    </div>
                                                    <div>
                                                      { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['end']).format('A') === "AM" ?
                                                        <Icon style={ {
                                                          fontSize: 20,
                                                        } } icon="line-md:sunny-outline-to-moon-alt-loop-transition" />
                                                        :
                                                        <Icon style={ {
                                                          fontSize: 20,
                                                        } } icon="line-md:moon-to-sunny-outline-loop-transition" />
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (<span style={ { color: "#EA4335" } }>Day Off</span>
                                              ) }
                                          </Button>
                                        </div> :
                                        <Popover placement="bottom" title={ '' } content={
                                          actions(date, employee.id, shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id) ?
                                            shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0] : null) } trigger="click">
                                          <div style={ {
                                            margin: 'auto',
                                            textAlign: 'center',
                                            padding: 8
                                          } }>
                                            <Button
                                              style={ {
                                                backgroundColor:
                                                  shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id) &&
                                                    shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0] ?
                                                     lightenColor(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['bg_colour'], 10) : 'rgba(234, 67, 53, 0.10)',
                                                  
                                                color:
                                                  dateTotalSource[date] === 0 ||
                                                    shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id)
                                                    ? ''
                                                    : '#687980',
                                                margin: 'auto',
                                                width: '100%',
                                                height: 80,
                                                padding: 0,
                                                border: 'none',
                                              } }
                                            >


                                              { dateTotalSource[date] === 0 ? (
                                                <span style={ { color: "#EA4335" } }>Day Off</span>) :
                                                shifts.hasOwnProperty(moment(date).format('YYYYMMDD') + '-' + employee.id) ? (
                                                  <div>
                                                    <div style={ {
                                                      color: '#000',
                                                      fontSize: 16,
                                                    } } >
                                                      { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['start']).format('hh:mm') } { " - " }
                                                      { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['end']).format('hh:mm') }
                                                    </div>
                                                    <div style={ {
                                                      display: 'flex',
                                                      flexDirection: 'row',
                                                      justifyContent: 'space-between',
                                                      paddingRight: 20,
                                                      paddingLeft: 20,
                                                      marginTop: 10,

                                                    } }>
                                                      <div>
                                                        { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['start']).format('A') === "AM" ?
                                                          <Icon style={ {
                                                            fontSize: 20,
                                                          } } icon="line-md:sunny-outline-to-moon-alt-loop-transition" />
                                                          :
                                                          <Icon style={ {
                                                            fontSize: 20,
                                                          } } icon="line-md:moon-to-sunny-outline-loop-transition" />
                                                        }
                                                      </div>
                                                      <div>
                                                        { convertTimeTotz(shifts[moment(date).format('YYYYMMDD') + '-' + employee.id][0]['end']).format('A') === "AM" ?
                                                          <Icon style={ {
                                                            fontSize: 20,
                                                          } } icon="line-md:sunny-outline-to-moon-alt-loop-transition" />
                                                          :
                                                          <Icon style={ {
                                                            fontSize: 20,
                                                          } } icon="line-md:moon-to-sunny-outline-loop-transition" />
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (<span style={ { color: "#EA4335" } }>Day Off</span>
                                                ) }
                                            </Button>
                                          </div>
                                        </Popover>
                                      }
                                    </div>
                                  </td >
                                </>
                              ))
                            }

                          </tr>
                        ))
                      }
                      <tr>
                        {
                          Object.keys(dateTotalSource).map((value, mins) => (
                            <td>
                              <div style={ { textAlign: 'center', } }>
                                <span style={ { fontSize: 16, color: '#687980' } }>{ dateTotalSource[value] ? convertMinutesToHours(dateTotalSource[value]) : '-' }</span>
                              </div>
                            </td>
                          ))
                        }
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Spin>
          </div>
          {/* </Spin> */ }
        </div >



      </BodyComponent >
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};
export default IndexCompanyRoaster;



