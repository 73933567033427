import React, { useEffect, useState } from "react";
import { Form, message, Row, Col, Spin } from "antd";
import {
  CancelButton, ModalComponent, trans, SelectWithSearch, BodyComponent,
  TableWithFilter,
  ActionComponent,
  ImportButton,
  DownloadButton,
  SaveButton
} from "@comps/components"
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { addUserEmpReq, downloadSampleMappingReq, getMachineByIdReq, syncUsersReq } from "../requests";
import { Commons, Const } from "../../../constants";
import ImportMapper from "./ImportMapper";
const langKey = Const["machines"].lngKey
const formName = "ViewMachineMap";

const ViewMachineMap = (props) => {
  const [loader, setLoader] = useState(false);
  const [saving, setSaving] = useState(false)
  const [employees, setEmployees] = useState([])
  const [mappedEmployees, setMappedEmployees] = useState([]);
  const [machineUsers, setMachineUsers] = useState([])
  const [loading, setloading] = useState(true);
  const [updated, setUpdated] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [childComponent, setChildComponent] = useState(null)
  const [form] = Form.useForm()
  useEffect(() => {
    getMachineById()
    // eslint-disable-next-line
  }, [updated]);

  const getMachineById = () => {
    setloading(true);
    makeRequest(setLoader, getMachineByIdReq, props.record.id, onSuccessMachine, null);
  }
  const onSuccessMachine = (response) => {
    //employees
    const employeesData = { employees: response.employees }
    const employeesArray = Object.values(employeesData.employees).map(employee => ({
      value: employee.id,
      label: employee.full_name
    }));


    //users
    const usersData = { machine_users: response.machine_users }
    const userArray = Object.values(usersData.machine_users).map(user => ({
      value: user.id,
      label: user.name_id
    }));


    const newEmp = reformatEmployees(employeesArray, response.mapped_employees)
    setEmployees(newEmp)
    setMachineUsers(userArray)
    setMappedEmployees(response.mapped_employees)
    form.setFieldsValue({
      employee_id: null,
      user_id: null
    })
    setloading(false)
  }

  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleMappingReq, props.record.id, onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'xlsx'
      const fileName = `mapper.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  const reformatEmployees = (emps, mappedEmp) => {
    let newEmployees = [];
    //set employees
    mappedEmp.forEach((each, key) => {

      const res = removeByValue(emps, each.employee_id)
      newEmployees = res
      emps = res
    })
    if(mappedEmp.length===0){
      newEmployees=emps;
    }
    return newEmployees;
  }
  const removeByValue = (data, value) => {
    return data.filter((each) => {
      return each.value !== value;
    });
  }
  //import mapper 
  const onImportMapper = () => {
    setChildComponent(<ImportMapper onCompleted={ onCompleted } machine_id={ props.record.id } />)
  }
  const onCompleted = (data) => {
    setChildComponent(null)
    setUpdated(!updated)
  }
  const onSyncUsers = () => {
    setSyncing(true)
    makeRequestStateless(syncUsersReq, props.record.id, onSuccessSync, onError);
  }
  const onSuccessSync = (data, response) => {
    setSyncing(false)
    message.success(response.message)
    const users = []
    data.forEach(element => {
      users.push({
        label: element.user_name+'-'+element.id,
        value: element.id
      })
    });
    setMachineUsers(users)
  }
  const onSubmit = (data) => {
    const dataToSave = []
    dataToSave.push(data)
    const payload = { machineId: props.record.id, additions: dataToSave };
    makeRequest(setLoader, addUserEmpReq, payload, onSuccess, onError);
    setSaving(true)
  }

  const onSuccess = (data, res) => {


    message.success("Added successfully");
    setUpdated(!updated)
  }

  const onError = (err, res) => {
    setUpdated(!updated)
    setSaving(false)
    message.error(res.response.data.message);
  }

  //============mapped employees================================
  const columns = [
    {
      key: 'employee_id',
      title: trans(`${langKey}.employee_id`),
      dataIndex: "employee_name",
    },
    {
      key: 'user_id',
      title: trans(`${langKey}.user_id`),
      dataIndex: "user_name_id",
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onDelete={ onDelete }
        >
        </ActionComponent>
      ),
      hideFilter: true
    }
  ]

  const onDelete = (record) => {
    const dataToSave = []
    dataToSave.push({ employee_id: record.employee_id, user_id: record.user_id })
    const payload = { machineId: record.machine_id, subtractions: dataToSave };
    makeRequest(setLoader, addUserEmpReq, payload, onSuccess, onError);
  }

  //-----------------------
  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------

  const footer = [
    <CancelButton key="close_button" onClick={ () => props.onCompleted(false) } />
  ];

  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (
    <ModalComponent width={ 1000 } maskClosable={ false }
      mainTitle={ trans(Commons.view) } subTitle={ trans(`${langKey}.map`) }
      visible={ true } footer={ footer } onCancel={ () => props.onCompleted(false) }>
      { childComponent }
      <Spin spinning={ loading }>
        <Form { ...formLayout } name={ formName } onFinish={ onSubmit } form={ form }>

          <Form.Item name="employee_id" rules={ rules.employee_id } label={ trans(`${langKey}.employee_id`) }
            className="da-mb-16"
            size="small"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.employee_id`) }
              options={ employees }
            />
          </Form.Item>

          <Form.Item name="user_id" rules={ rules.user_id } label={ trans(`${langKey}.user_id`) }
            className="da-mb-16"
            size="small"
          >
            <SelectWithSearch
              placeholder={ trans(`${langKey}.user_id`) }
              options={ machineUsers }
            />
          </Form.Item>
          <Row gutter={ 6 } >
            <Col span={ 24 } style={ {
              textAlign: "right"
            } }>
              <SaveButton className="me-2" loading={ syncing } text='Sync Users' form={ formName } key="sync_button" onClick={ onSyncUsers } />
              <SaveButton className="me-2" loading={ saving } text='Add' form={ formName } key="create_button" htmlType="submit" />
              <DownloadButton className="me-2" form={ formName } key="download_button" onClick={ onDownloadSample } />
              <ImportButton className="me-2" text='Import Mapper' form={ formName } key="import_button" onClick={ onImportMapper } />
            </Col>
          </Row>
        </Form>

      
      </Spin>

      <h5 className="formlist-title">Mapped Employees</h5>
        <BodyComponent>

<div style={ {
  height: "350px",
  overflowY: "scroll"
} }>
  <TableWithFilter
    loader={ loader }
    columns={ columns }
    data={ mappedEmployees }
  />
</div>
</BodyComponent>
    </ModalComponent>
  )
}

export default ViewMachineMap

const rules = {
  employee_id: [
    { required: true, message: trans(`${langKey}.employee_id`) },
  ],
  user_id: [
    { required: true, message: trans(`${langKey}.user_id`) },
  ],
};

const formLayout = {
  // layout: "horizontal",
  labelCol: { span: 6 },
  wrapperCol: { span: 14, offset: 2 },
  labelAlign: "left"
}