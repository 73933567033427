import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  trans,
  HeaderComponent,
  EmployeeWithProfile,
  PaginationComponent
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getDelegatesReq, getFilterReq } from "./requests";
import moment from "moment/moment";
import RequestAsTab from "@mods/common/RequestAsTab";
import UpdateExpenseApproval from "./components/UpdateExpenseApproval";
import ViewApprovalHistory from "@mods/common/ViewApprovalHistory";

const langKey = Const["expenseApproval"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["expenseApproval"].route
      }
    ]
  }
}

const IndexExpenseDelegates = (props) => {
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [asFilters, setAsFilters] = useState([]);
  const [requestAs, setRequestAs] = useState(8)
  const [delegator, setDelegator] = useState('')
  const [noOfNotifications, setNoOfnotifications] = useState(null)
  const [filters, setFilters] = useState();
  const [allData, setAllData] = useState({})
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ================================== UpdateLocation
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'department_name',
      title: trans(`${langKey}.department_name`),
      render: (record) => {
        return record.requestor.department_name;
      }
    },
    {
      key: 'next_requestee',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.next_requestee_name } />
      },
    },

    {
      key: 'dates',
      title: trans(`${langKey}.dates`),
      render: (record) => {
        return moment(record.start).format('YYYY-MM-DD') + '-to-' + moment(record.end).format('YYYY-MM-DD')
      }
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },

    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onViewHistory={ (record.status === 'Completed' || record.status === 'Rejected' || record.status === 'Cancelled') ? onViewHistory : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
          onEdit={ (record.status === 'Pending') ? onEdit : '' }
          onView={ onView }
        ></ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    onOpenFilter()
    // eslint-disable-next-line
  }, [currentPage]);

  const getAllData = (requestAs) => {
    let payload = {
      api: 'expenseDelegates',
      page: currentPage,
      filters: filters,
      data: { request_as: requestAs, delegator_id: delegator }
    }
    makeRequest(setLoader, getDelegatesReq, payload, onSuccess, null);
  };

  const onSuccess = (response) => {
    setNoOfnotifications(response.expenses.length);
    setPagination(response.expenses);
    setDataSource(response.expenses.data);
    setAsFilters(response.filters);
    setAllData(response)
  };

  const onEdit = (record) => {
    setChildComponent(
      <UpdateExpenseApproval onCompleted={ onCompleted } record={ record } disabled={ false } requestAsId={ requestAs } allData={ allData } />
    );
  };

  const onView = (record) => {
    setChildComponent(
      <UpdateExpenseApproval onCompleted={ onCompleted } record={ record } disabled={ true } requestAsId={ requestAs } allData={ allData } />
    );
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewApprovalHistory onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      //<ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } />
    );
  };

  const onDelete = (record) => {
    makeRequest(setLoader, deleteDataReq, record.id, onDeleted, onError);
  };
  const onDeleted = (res) => {
    setDataSource(res.leave_requests.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
  }

  // Create component modal
  const onCreate = () => {
    //setChildComponent(<CreateEvaluation onCompleted={ onCompleted } />);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ onApplyFilter } onCloseFilter={ onCloseFilter }
    />
    )
  }
  const onApplyFilter = (data) => {
    // Split the data into key-value pairs
    const keyValuePairs = data.split(';');

    // Initialize an object to store the key-value pairs
    const keyValueObject = {};

    // Iterate through each key-value pair
    keyValuePairs.forEach(pair => {
      // Split each pair into key and value
      const [key, value] = pair.split('_|');

      // Add the key-value pair to the object
      keyValueObject[key] = value;
    });

    // Retrieve the value associated with the "delegator" key
    const delegatorValue = keyValueObject['delegator'];
    setDelegator(delegatorValue)
    getAllData(requestAs)
    setFilters(data)
    onCloseFilter()
  }
  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------
  //=============================Tab Menus========================================

  //-----------end use states--------------------


  //==============================================================================
  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <RequestAsTab getAllData={ getAllData } setAsFilters={ asFilters } setRequestAs={ setRequestAs } />
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexExpenseDelegates;
const filterFields = [
  {
    type: "select",
    placeholder: trans(`${langKey}.location`),
    key: "location",
    data_key: "organization_structure_data.location",
    //is_resource: true
  },
  {
    type: "select",
    placeholder: trans(`${langKey}.branch`),
    key: "branch",
    data_key: "organization_structure_data.branch",
    //is_resource: true
  },
  {
    type: "select",
    multiple: true,
    placeholder: trans(`${langKey}.department`),
    key: "department",
    data_key: "organization_structure_data.department",
     filterKey: "hierarchy_code"
    //is_resource: true
  },
  {
    type: "select",
    multiple: true,
    placeholder: trans(`${langKey}.employees`),
    key: "employees",
    data_key: "supporting_data.employments",
       resource_type:'employment'
  },
  {
    type: "select",
    multiple: false,
    placeholder: trans(`${langKey}.delegator`),
    key: "delegator",
    data_key: "supporting_data.employments",
        resource_type:'employment',
    required: true
    //is_resource: true
  },

]

