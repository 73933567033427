import { HeaderComponent, BodyComponent, TableComponent, ActionComponent, CreateButton,RefreshButton, trans, FilterComponent, FilterButton, PaginationComponent, EmployeeWithProfile, ImportButton ,DownloadButton} from "@comps/components";
import { makeRequest, makeRequestStateless, handleDownloadFile } from "@utils/helpers";
import { useState, useEffect } from 'react';
import { getAllDataReq, getFilterReq } from "./requests"
import { Commons, Const } from "../constants"
import CreateEndOfEmployment from "./components/CreateEndOfEmployment";
import UpdateEndOfEmployment from "./components/UpdateEndOfEmployment";
import { message, Tooltip } from "antd";
import { AuditOutlined, UserOutlined } from "@ant-design/icons";
import ConductEoeInterview from "./components/ConductEoeInterview";
import { getOptionalDatesFilters, getOrganizationFilters } from "../../common/commonFilterFields";
import ImportSample from "../../common/ImportSample";
import { downloadSampleReq } from "../../common/requests";

// ==================================
//   Page Breadcrumbs Start
// ==================================
const langKey = Const["EOEmployment"].lngKey

const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["EOEmployment"].route
      }
    ]
  }
}

// ==================================
//   Page Breadcrumbs End
// ==================================

const IndexEndOfEmployments = (props) => {

  // ==================================
  //   Use States Start
  // ==================================
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filters, setFilters] = useState();
  const [childComponent, setChildComponent] = useState(null);
  //start pagination states
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });
  //end pagination states

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: trans(Commons.name),
      title: trans(Commons.name),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      }
    },
    {
      key: trans(Commons.branch),
      title: trans(Commons.branch),
      render: (record) => {
        return record.requestor.branch_name
      }
    },
    {
      key: trans(Commons.location),
      title: trans(Commons.location),
      render: (record) => {
        return record.requestor.location_name
      }

    },
    {
      key: trans(Commons.department),
      title: trans(Commons.department),
      render: (record) => {
        return record.requestor.department_name
      }
    },
    {
      key: trans(Commons.designation),
      title: trans(Commons.designation),
      render: (record) => {
        return record.requestor.designation_name
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => <ActionComponent modkey={props.modkey} each={ record } onView={ onView }

      >
        <Tooltip title="Rehire">
          <UserOutlined className="da-px-10 da-my-0" onClick={ () => onRehire(record) } />
        </Tooltip>
        <Tooltip title={ record.interview_answers && record.interview_answers.length > 0 ? 'Interview Already Conducted' : 'Coduct Interview' }>
          <AuditOutlined onClick={ () => onInterview(record) } style={ { marginLeft: '5px' } } />
        </Tooltip>
      </ActionComponent>
    },
  ];
  // ==================================
  //   Table Data End
  // ==================================

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [filters, currentPage]);

  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: filters
    }
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, null);
  }

  const onSuccess = (response) => {
    setReasons(response.reasons);
    setPagination(response.end_of_employments);
    setDataSource(response.end_of_employments.data);
  }

  const onRehire = (record) => {
    setChildComponent(<UpdateEndOfEmployment onCreated={ onCreated } record={ record } disabled={ false } />);
  }
  const onInterview = (record) => {
    if (record.interview_answers && record.interview_answers.length > 0) {
      onView(record)
    }
    else {
      setChildComponent(<ConductEoeInterview onCreated={ onCreated } record={ record } disabled={ false } />);
    }

  }

  const onView = (record) => {
    setChildComponent(<UpdateEndOfEmployment onCreated={ onCreated } record={ record } disabled={ true } />);
  }

  const onCreate = () => {
    setChildComponent(<CreateEndOfEmployment onCreated={ onCreated } reasons={ reasons } onSuccess={ onSuccess } />);
  }
  const onCreated = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }
  //---------------filters-----------------------
  

  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter } moduleName="address"
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);
  }

  //----end filter--------------------
  //download sample log
  const onDownloadSample = () => {
    makeRequestStateless(downloadSampleReq, 'endofmployments/downloadSample', onSuccessDownloadSample, onSuccessDownloadSample);
  }
  const onSuccessDownloadSample = (res, err) => {
    if (res.code === undefined) {
      // Extract file extension
      const fileExtension = res.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : 'pdf'
      const fileName = `end_of_employments.${fileExtension}`
      handleDownloadFile(fileName, res)
    }
    else {
      message.error(res.message)
    }
  }
  // ==================================
  //   Component JSX Start
  // ==================================

  return (
    <>
      
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
        <RefreshButton onClick={ getAllData } />
        <ImportButton onClick={ () => setChildComponent(<ImportSample onSuccess={getAllData} api="endofmployments/import" module="End of Employments" onCompleted={ () => setChildComponent(null) } />) } />
        <DownloadButton onClick={ onDownloadSample } />


        <CreateButton onClick={ onCreate } />
        <FilterButton onClick={ onOpenFilter } />
      </HeaderComponent>
      <BodyComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange } />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  )
}


// ==================================
//   Component JSX End
// ==================================
export default IndexEndOfEmployments

const filterArray = getOrganizationFilters('')
filterArray.unshift({
  type: "select",
  placeholder: 'Employee',
  key: "employees",
  data_key: "supporting_data.employees",
  required: false,
  resource_type: 'employment',
  filters: "trashed_type_|onlyTrashed",
  multiple:true,
  onlyTrashed:true
})

filterArray.push({
      type: "text",
       placeholder: "CNIC",
      key: "cnic",
     });
     
const filterFields = filterArray.concat(getOptionalDatesFilters(''))
// const filterFields = [
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.select.employee`),
//     key: "employments",
//     data_key: "supporting_data.employees",
//     resource_type: 'employment'
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectlocation`),
//     key: "location",
//     data_key: "organization_structure_data.location",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectbranch`),
//     key: "branch",
//     data_key: "organization_structure_data.branch",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectdepartment`),
//     key: "department",
//     data_key: "organization_structure_data.department",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectgrades`),
//     key: "grade",
//     data_key: "supporting_data.grade",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectdesignation`),
//     key: "designation",
//     data_key: "supporting_data.designation",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectGender`),
//     key: "gender",
//     data_key: "supporting_data.gender",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.islinemanager`),
//     key: "is_line_manager",
//     data_key: "supporting_data.is_line_manager",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.ishod`),
//     key: "is_hod",
//     data_key: "supporting_data.is_hod",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.isfinalauthority`),
//     key: "is_final_authority",
//     data_key: "supporting_data.is_final_authority",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectjobtitle`),
//     key: "job_title",
//     data_key: "supporting_data.job_titles",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectemployeetype`),
//     key: "employee_type",
//     data_key: "supporting_data.employee_types",
//     //is_resource: true
//   },
//   {
//     type: "select",
//     placeholder: trans(`${langKey}.selectemploymenttype`),
//     key: "employment_type",
//     data_key: "supporting_data.employment_types",
//     //is_resource: true
//   },
//   {
//     type: "text",
//     placeholder: "CNIC",
//     key: "cnic",
//   },

// ]
