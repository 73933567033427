import { useState, useEffect } from "react";
import {
  BodyComponent,
  TableComponent,
  ActionComponent,
  CreateButton,RefreshButton,
  trans,
  HeaderComponent,
  PaginationComponent,
  EmployeeWithProfile
} from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { Commons, Const, colors } from "../../constants";
import { Badge, message } from 'antd';
import { FilterComponent, FilterButton } from "@comps/components";
import { deleteDataReq, getAllDataReq, getFilterReq } from "./requests";
import { useSelector } from "react-redux";

import ViewFlow from '@mods/common/ViewFlow';
import ViewLeaveRequestHistory from "../../common/ViewLeaveRequestHistory";
import CreateRelaxationRequest from "./components/CreateRelaxationRequest";
import ViewRelaxationRequest from "./components/ViewRelaxationRequest";
import { getRequestFilters } from '@mods/common/commonFilterFields';
import { convertTimeTotz } from '@comps/commonFunctions';
import { useLocation } from "react-router-dom";
const langKey = Const["relaxationRequest"].lngKey;
const pageConfig = {
  headers: {
    title: trans(`${langKey}.moduleName`),
    breadcrumb: [
      {
        name: trans(`${langKey}.moduleName`),
        path: Const["relaxationRequest"].route
      }
    ]
  }
}

const IndexCompanyRelaxationRequests = (props) => {
  const authUser = useSelector((state) => state.auth.authUser);
  const totalRecords=0;
  const [dataSource, setDataSource] = useState([]);
  const [loader, setLoader] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  
  const [filters, setFilters] = useState('');
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    "total": 0,
    "count": 0,
    "per_page": 0,
    "current_page": 0,
    "total_pages": 0,
    "links": {}
  });

  // ==================================
  //   Use States End
  // ==================================

  // ==================================
  //   Table Data End
  // ==================================

  const columns = [
    {
      key: 'code',
      title: trans(Commons.code),
      dataIndex: "code",
    },
    {
      key: 'employment_id',
      title: trans(`${langKey}.employment_id`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.requestor } />
      },
    },
    {
      key: 'date',
      title: trans(`${langKey}.date`),
      render: (record) => {
        return convertTimeTotz(record.date).format('YYYY-MM-DD')
      }
    },
    {
      key: 'time_slot_name',
      title: trans(`${langKey}.time_slot_name`),
      dataIndex: "time_slot_name",
    },
    {
      key: 'time',
      title: trans(`${langKey}.time`),
      render: (record) => {
        return convertTimeTotz(record.start_time).format('hh:mm a') + '-' + convertTimeTotz(record.end_time).format('hh:mm a')
      }
    },
    {
      key: 'duration',
      title: trans(`${langKey}.duration`),
      render: (record) => {
        return (Math.floor(record.minutes) >= 60 ? Math.floor(record.minutes / 60) + 'h ' + record.minutes % 60 + 'm' : Math.floor(record.minutes) + 'm')
      }
    },
    {
      key: 'next_requestee',
      title: trans(`${langKey}.next_requestee`),
      render: (record) => {
        return <EmployeeWithProfile record={ record.next_requestee_name } />
      },
    },
    {
      key: 'status',
      title: trans(`${langKey}.status`),
      render: (record) => {
        return <Badge
          count={ record.status ? record.status : 'none' }
          style={ { background: colors[record.status_class], color: colors[record.status_class + '-color'] } }
        />
      }
    },
    {
      key: trans(Commons.actions),
      title: trans(Commons.actions),
      render: (record) => (
        <ActionComponent
          each={ record } modkey={props.modkey}
          onViewHistory={ record.status !== 'Pending' ? onViewHistory : '' }
          onViewFlow={ record.flow.length !== 0 ? onViewFlow : '' }
          onEdit={ record.status === 'Pending' ? onEdit : '' }
          onView={ onView }
          onDelete={ record.status === 'Pending' ? onDelete : '' }
          onCancelRequest={ (record.status !== 'Pending') ? (record.status !== 'Cancelled') ? onCancelRequest : '' : '' }
        >
        </ActionComponent>
      ),
    },
  ];

  // ==================================
  //   Table Data End
  // ==================================
  const location = useLocation();
  useEffect(() => {
    getAllData();
    // eslint-disable-next-line
  }, [location.pathname,filters, currentPage]);
  const getAllData = () => {
    const payload = {
      page: currentPage,
      filters: `${props.filters};${filters}`,
      api:props.api
    }
    setChildComponent(null)
    makeRequest(setLoader, getAllDataReq, payload, onSuccess, onGetAllDataError);
  };
  const onSuccess = (response) => {
    setPagination(response.relaxations);
    setDataSource(response.relaxations.data);
  };
  const onGetAllDataError = (err, res) => {
    message.error(res.response.data.message);
  }
  const onEdit = (record) => {
    setChildComponent(
      <ViewRelaxationRequest onCompleted={ onCompleted } record={ record } disabled={ false } action='edit' getAllData={ getAllData } filters={props.filters} api={props.api} props={props}/>
    );
  };
  const onView = (record) => {
    setChildComponent(
      <ViewRelaxationRequest onCompleted={ onCompleted } record={ record } disabled={ true } action='view' props={props}/>
    );
  };
  const onCancelRequest = (record) => {
    setChildComponent(
      <ViewRelaxationRequest onCompleted={ onCompleted } record={ record } disabled={ true } action='cancel' />
    );
  };
  const onViewHistory = (record) => {
    setChildComponent(
      <ViewLeaveRequestHistory onCompleted={ onCompleted } record={ record } disabled={ true } subTitle={ trans(Commons.history) } />
    );
  };
  const onViewFlow = (record) => {
    setChildComponent(
      <ViewFlow onCompleted={ onCompleted } record={ record } disabled={ true } subTitle={ trans(Commons.flow) } />
    );
  };
  const onDelete = (record) => {
    const payload = {
      filters: '', id: record.id
    }
    makeRequest(setLoader, deleteDataReq, payload, onDeleted, onError);
  };
  const onDeleted = (res) => {
    onSuccess(res)
    setDataSource(res.relaxations.data);
    message.success("Record Deleted")
  };

  const onError = (err, res) => {
    message.error(res.response.data.message);
    //setErrors(errorList);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateRelaxationRequest onCompleted={ onCompleted } authUser={ authUser } relaxationof={ props.relaxationof } onSuccess={ onSuccess } filters={props.filters} api={props.api} props={props}/>);
  };

  const onCompleted = (data) => {
    if (data.length) {
      setDataSource(data);
    }
    setChildComponent(null);
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || "id",
      sortType: sorter.order === "ascend" ? "asc" : "desc",
    };
    setPagination(payload);
  };

  //---------------filters-----------------------
  const onOpenFilter = () => {
    setChildComponent(<FilterComponent filters={ filterFields } modkey={props.modkey} selected={ filters } api={ getFilterReq } setCurrentPage={setCurrentPage} onFilter={ setFilters } onCloseFilter={ onCloseFilter }
    />
    )
  }

  const onCloseFilter = () => {
    setChildComponent(null);

  }

  //----end filter--------------------

  return (
    <>
      
      { childComponent }
      <BodyComponent>
        <HeaderComponent headers={ pageConfig.headers } modkey={props.modkey}>
          <RefreshButton onClick={ getAllData } /> <CreateButton onClick={ onCreate } />
          <FilterButton onClick={ onOpenFilter } />
        </HeaderComponent>
        <TableComponent modkey={props.modkey}
          loader={ loader }
          columns={ columns }
          dataSource={ dataSource }
          pagination={ { ...pagination, total: totalRecords } }
          onChange={ handleTableChange }
        />
      </BodyComponent>
      <PaginationComponent pagination={ pagination } modkey={props.modkey} setCurrentPage={ setCurrentPage } currentPage={ currentPage } />
    </>
  );
};

export default IndexCompanyRelaxationRequests;
const filterFields = getRequestFilters('')

